import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import * as jsonpatch from "fast-json-patch";
import { enableMapSet } from "immer";
import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";
import isBoolean from "lodash/isBoolean";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isFinite from "lodash/isFinite";
import isInteger from "lodash/isInteger";
import isNumber from "lodash/isNumber";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import omit from "lodash/omit";
import pick from "lodash/pick";
import reverse from "lodash/reverse";
import shuffle from "lodash/shuffle";
import Router, { useRouter } from "next/router";
import damerauLevenshtein from "talisman/metrics/damerau-levenshtein";
import { useImmer } from "use-immer";

import { apiUploadAvatar } from "@/api/avatar";
import { apiGenerateQuiz, apiGetStatus } from "@/api/generator";
import {
	apiCreateSession,
	apiGetQuiz,
	apiGetRandomQuizzes,
	apiGetRandomSlides,
	apiPatchAudioClip,
	apiPostQuizRating,
	apiPostSavePlayedSlide,
	apiPostSlideResults,
	apiUpdateSession,
} from "@/api/quiz";

import Avatar from "@/components/Avatar";
import ConfirmDialog from "@/components/ConfirmDialog";
import Modal from "@/components/Modal";
import QuizVote, { PLAY_STATUSES_VOTE } from "@/components/QuizVote";
import ContinueButton from "@/components/interactives/ContinueButton";
import Billboard from "@/components/pages/Billboard";
import { StateView } from "@/components/pages/GuestPage";
import { getGrid } from "@/components/pages/edit/RangeSlideEditor";
import AvatarCustomize from "@/components/pages/join/AvatarCustomize";
import BackgroundAnimation from "@/components/pages/play/BackgroundAnimation";
import Connecting from "@/components/pages/play/Connecting";
import GameEnd from "@/components/pages/play/GameEnd";
import GamePaused from "@/components/pages/play/GamePaused";
import GetReady from "@/components/pages/play/GetReady";
import HideMouse from "@/components/pages/play/HideMouse";
import Leaderboard from "@/components/pages/play/Leaderboard";
import LeaveDialog from "@/components/pages/play/LeaveDialog";
import Loading from "@/components/pages/play/Loading";
import Lobby from "@/components/pages/play/Lobby";
import PlayerScore from "@/components/pages/play/PlayerScore";
import Playlist from "@/components/pages/play/Playlist";
import Prefetch from "@/components/pages/play/Prefetch";
import RateModal from "@/components/pages/play/RateModal";
import ScaleWrapper from "@/components/pages/play/ScaleWrapper";
import Slide from "@/components/pages/play/Slide";

import { firstNames, lastNames } from "@/data/names";

import { getPointsFromAnswerProgress } from "@/helpers/answerTimes";
import { getAnswerTime } from "@/helpers/answerTimes";
import { sfx } from "@/helpers/audio";
import { voicePlayer } from "@/helpers/audio";
import getInitialAvatar from "@/helpers/getInitialAvatar";
import getInitialAvatarName from "@/helpers/getInitialAvatarName";
import getInitialAvatarSafeName from "@/helpers/getInitialAvatarSafeName";
import getMediaBySlide, { getFunFactMediaBySlide } from "@/helpers/getMediaBySlide";
import getRandomAvatarName from "@/helpers/getRandomAvatarName";
import getVerifiedAvatarSafeName from "@/helpers/getVerifiedAvatarSafeName";
import gsap from "@/helpers/gsap";
import { googleAnalyticsEvent } from "@/helpers/gtag";
import isUUID from "@/helpers/isUUID";
import { haversineDistance } from "@/helpers/map";
import { deserialize } from "@/helpers/map";
import noSleep from "@/helpers/noSleep";
import { getConnectedPlayers, isPlayerConnected } from "@/helpers/player";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";
import { clearInterval, clearTimeout, setInterval, setTimeout } from "@/helpers/workerTimers";

import useBoostGuard from "@/hooks/useBoostGuard";
import useGeoFeature from "@/hooks/useGeoFeature";
import useRateQuizzes from "@/hooks/useRateQuizzes";
import useRefCallback from "@/hooks/useRefCallback";
import useRefMounted from "@/hooks/useRefMounted";
import useStatusSequence from "@/hooks/useStatusSequence";
import useStatusWithProgress from "@/hooks/useStatusWithProgress";
import useStopHosting from "@/hooks/useStopHosting";

import useAIStore from "@/stores/ai";
import useAudioStore from "@/stores/audio";
import useAuthStore from "@/stores/auth";
import usePlayStore from "@/stores/play";
import usePlayerStore from "@/stores/player";
import useSettingsStore from "@/stores/settings";
import useStatusWithProgressStore from "@/stores/statusWithProgress";
import useUserCacheStore from "@/stores/userCache";
import useWebSocketStore from "@/stores/webSocket";

import { createAvatar, createTeam, createTeamAvatar } from "@/types/team";

import {
	ROOM_CLIENTS,
	ROOM_CREATE,
	ROOM_JOIN,
	ROOM_LEAVE,
	ROOM_MESSAGE,
	ROOM_SETTINGS_OPEN,
} from "@/webSocket/webSocket";

import {
	GENERATOR_REASON_BAD_TOPIC,
	PLACE_CODE_SEPARATOR,
	SLIDE_TYPE_CHECK_BOXES,
	SLIDE_TYPE_CLASSIC,
	SLIDE_TYPE_INFO_SLIDE,
	SLIDE_TYPE_LOCATION,
	SLIDE_TYPE_PINPOINT,
	SLIDE_TYPE_RANGE,
	SLIDE_TYPE_REORDER,
	SLIDE_TYPE_TYPE_ANSWER,
} from "@/app-constants.mjs";
import {
	BACKGROUND1,
	BACKGROUND2,
	BACKGROUND3,
	BACKGROUND4,
	BACKGROUND5,
	BACKGROUND6,
	BACKGROUND7,
	BACKGROUND8,
	GREEN_LIGHT,
	PETROL_DARK,
	PETROL_DARKEST,
	PINK,
	PINK_LIGHT,
	YELLOW,
	YELLOW_DARK,
} from "@/colors";
import {
	API_WEB_SOCKET_GATEWAY_URL,
	BOOST_STATUS_FREE,
	BOOST_STATUS_SUBSCRIBED,
	BOOST_TYPE_GENERATE_QUIZ,
	CDN_BASE_URL,
	MAX_NR_OF_PLAYERS,
	MAX_NR_OF_PLAYERS_ADMIN,
	PLACE_TYPE_STATE,
	PLAYER_NAME_TTS_TRANSFORM,
	PLAYING_HOST_CONNECTION_ID,
	PLAY_STATUES_SLIDE,
	PLAY_STATUS_AI_DISCLAIMER,
	PLAY_STATUS_ALL_ANSWERS_RECEIVED,
	PLAY_STATUS_BEFORE_LAST_SLIDE,
	PLAY_STATUS_BROWSE_MAP,
	PLAY_STATUS_EXIT,
	PLAY_STATUS_FLUSH,
	PLAY_STATUS_GAME_START,
	PLAY_STATUS_HIDE_CORRECT_ANSWER,
	PLAY_STATUS_HIDE_FUN_FACT,
	PLAY_STATUS_HIDE_GET_READY,
	PLAY_STATUS_HIDE_SLIDE,
	PLAY_STATUS_LOAD_QUIZ,
	PLAY_STATUS_LOAD_SLIDE,
	PLAY_STATUS_PREP_LEADERBOARD,
	PLAY_STATUS_RATE_DONE,
	PLAY_STATUS_SHOW_ANSWERS,
	PLAY_STATUS_SHOW_AVATAR_COLORS,
	PLAY_STATUS_SHOW_AVATAR_CORRECTNESS,
	PLAY_STATUS_SHOW_CORRECT_ANSWER,
	PLAY_STATUS_SHOW_FUN_FACT,
	PLAY_STATUS_SHOW_GET_READY,
	PLAY_STATUS_SHOW_LEADERBOARD,
	PLAY_STATUS_SHOW_MAP_PIN,
	PLAY_STATUS_SHOW_MEDIA,
	PLAY_STATUS_SHOW_QUESTION,
	PLAY_STATUS_VOTE_COMING_UP,
	PLAY_STATUS_VOTE_END,
	PLAY_STATUS_VOTE_PREP,
	PLAY_STATUS_VOTE_SHOW,
	PLAY_STATUS_VOTE_SHOW_NEXT_QUIZ,
	PLAY_STATUS_WAIT_FOR_ANSWER,
	PLAY_STATUS_WAIT_FOR_MEDIA,
	PLAY_STATUS_YOUTUBE_END_1,
	PLAY_STATUS_YOUTUBE_END_2,
	PLAY_STATUS_ZOOM_MAP_PIN,
	QUIZ_AI_ID,
	QUIZ_DEAL_ID,
	QUIZ_STOP_ID,
	QUIZ_VOTEMODE_ID,
	RANGE_SLIDE_ERROR_SCORE,
	RANGE_SLIDE_ERROR_SCORE_BREAKPOINT,
	SLIDE_SKIP_STATUSES,
	VOTE_MODE_CRAZY_CLICK_MODE,
	VOTE_MODE_HOST_DECIDES,
	VOTE_MODE_NORMAL,
	WEB_SOCKET_URL,
} from "@/constants";
import {
	PLAY_STATUS_LOBBY,
	PLAY_STATUS_SHOW_INSTRUCTOR_PACED,
	PLAY_STATUS_SHOW_RATE,
	PLAY_STATUS_SHOW_WINNER,
	STREET_VIEW_PORTAL_ID,
} from "@/constants";

import Header from "../Header";
import LayoutBackground from "../LayoutBackground";
import AIDisclaimer from "./AIDisclaimer";
import { decodePath, inside } from "./edit/PinpointSlideEditor";
import { AvatarCustomizeModal } from "./join/AvatarCustomizeModal";
import { AvatarCustomizeModalTeam } from "./join/AvatarCustomizeModalTeam";

enableMapSet();

const NUMBER_OF_OMIT_QUIZZES = 50;

const DEBUG_TULOU_IS_ALWAYS_RIGHT = true;
const VOTE_THROTTLE_TIMEOUT = 300;
const VOTE_STATE_UPDATE_THROTTLE = 500;

const API_WEB_SOCKET_PING_TIMEOUT = 10000;
const PLAYER_DISCONNECT_TIMEOUT = 20000;

const SCALE_WIDTH = 1920;
const SCALE_HEIGHT = 1080;
const SCALE_PADDING = { top: 4 * 4 + 11 * 4, left: 4 * 4, bottom: 4 * 4, right: 4 * 4 };

const BACKGROUND_COLORS = [
	BACKGROUND1,
	BACKGROUND2,
	BACKGROUND3,
	BACKGROUND4,
	BACKGROUND5,
	BACKGROUND6,
	BACKGROUND7,
	BACKGROUND8,
];

export default function HostPage(props) {
	const [mounted, mountedRef] = useRefMounted();

	const router = useRouter();
	const routerRef = useRef(router);
	useEffect(() => void (routerRef.current = router), [router]);

	const setCode = usePlayStore((state) => state.setCode);
	const setQuiz = usePlayStore((state) => state.setQuiz);
	const updatePlayer = usePlayStore((state) => state.updatePlayer);
	const updatePlayers = usePlayStore((state) => state.updatePlayers);
	const quiz = usePlayStore((state) => state.quiz);
	const isPaused = usePlayStore((state) => state.paused);
	const setPaused = usePlayStore((state) => state.setPaused);
	const slideIndex = usePlayStore((state) => state.slideIndex);
	const statusName = useStatusWithProgressStore((state) => state.name);
	const leaderboard = usePlayStore((state) => state.leaderboard);
	const report = usePlayStore((state) => state.report);
	const slideLoaded = usePlayStore((state) => state.slideLoaded);
	const setSlideLoaded = usePlayStore((state) => state.setSlideLoaded);
	const setSlideComplete = usePlayStore((state) => state.setSlideComplete);
	const haveLocalPlayer = usePlayStore((state) => state.haveLocalPlayer);
	const setPlaylistSkip = usePlayStore((state) => state.setPlaylistSkip);
	const recommendedQuizzes = usePlayStore((state) => state.recommendedQuizzes);
	const nextQuizId = usePlayStore((state) => state.nextQuiz);
	const setNextQuizId = usePlayStore((state) => state.setNextQuiz);
	const updateDuration = useStatusWithProgressStore((state) => state.updateDuration);

	const boostGuard = useBoostGuard(BOOST_TYPE_GENERATE_QUIZ);

	const players = usePlayStore((state) => state.players);
	const playersRef = useRef(players);
	useEffect(() => void (playersRef.current = players), [players]);

	const playersArray = useMemo(() => Array.from(players.values()), [players]);
	const [connectedPlayersArray, disconnectedPlayersArray] = useMemo(() => {
		const connected = [];
		const disconnected = [];
		for (const player of playersArray) {
			(isPlayerConnected(player) ? connected : disconnected).push(player);
		}
		return [connected, disconnected];
	}, [playersArray]);

	/*
	// Debug
	useEffect(() => {
		console.log("Players:", [...players.values()]);
	}, [players]);
	*/

	const backgroundAnimationEnabled = useSettingsStore((state) => !!state.backgroundAnimationEnabled);

	const safeNames = useSettingsStore((state) => state.safeNames);
	const safeNamesRef = useRef(safeNames);
	useEffect(() => void (safeNamesRef.current = safeNames), [safeNames]);

	const excludeYoutube = useSettingsStore((state) => state.excludeYoutube);
	const setSettingsStore = useSettingsStore((state) => state.set);
	const voiceOverride = useSettingsStore((state) => state.voiceOverride);
	const hideIncorrectTypeAnswers = useSettingsStore((state) => state.hideIncorrectTypeAnswers);
	const showCode = useSettingsStore((state) => !!state.showCode);
	const joinOpen = useSettingsStore((state) => !!state.joinOpen);
	const muteGuest = useSettingsStore((state) => !!state.muteGuest);
	const mutePlayerNames = useSettingsStore((state) => !!state.mutePlayerNames);
	const reducedMotion = useSettingsStore((state) => !!state.reducedMotion);
	const numQuestionsPerRound = useSettingsStore((state) => state.numQuestionsPerRound);
	const numRoundsPerGame = useSettingsStore((state) => state.numRoundsPerGame);
	const hidePlayerCountry = useSettingsStore((state) => !!state.hidePlayerCountry);

	const teamMode = useSettingsStore((state) => !!state.teamMode);
	const teamModeRef = useRef(teamMode);
	useEffect(() => void (teamModeRef.current = teamMode), [teamMode]);

	const updateTeams = usePlayStore((state) => state.updateTeams);
	const teams = usePlayStore((state) => state.teams);
	const teamsRef = useRef(teams);
	useEffect(() => void (teamsRef.current = teams), [teams]);

	useEffect(() => {
		if (teamMode) {
			const originalTeamsArray = [...teams.values()];
			const teamsArray = cloneDeep(originalTeamsArray);

			// Make sure there are at least two teams
			while (teamsArray.length < 2) {
				const team = createTeam(trans("Team %d", teamsArray.length + 1));
				teamsArray.push(team);
			}

			// Make sure every team has an avatar
			const omitUrls = [];
			for (const team of teamsArray) {
				if (!team.avatarLayers) {
					team.avatarLayers = createTeamAvatar(omitUrls);
				}
				omitUrls.push(...team.avatarLayers.map((layer) => layer.url).filter(Boolean));
			}

			// Update teams if necessary
			if (!isEqual(originalTeamsArray, teamsArray)) {
				updateTeams((teams) => {
					teams.clear();
					for (const team of teamsArray.slice(teams.size)) {
						teams.set(team.connectionId, team);
					}
				});
			}

			// Make sure all players are in a valid team

			const teamSizes = teamsArray.map((team) => ({
				connectionId: team.connectionId,
				numPlayers: connectedPlayersArray.filter((player) => player.teamId === team.connectionId),
			}));
			const validTeamIds = new Set(teamsArray.map((team) => team.connectionId));

			// Make sure all players are in a valid team
			for (const player of [...connectedPlayersArray, ...disconnectedPlayersArray]) {
				if (!validTeamIds.has(player.teamId)) {
					const smallestTeamIndex = teamSizes.reduce(
						(minIndex, { numPlayers }, index, arr) =>
							numPlayers < arr[minIndex].numPlayers ? index : minIndex,
						0
					);

					updatePlayer(
						player.connectionId,
						(player) => void (player.teamId = teamsArray[smallestTeamIndex].connectionId)
					);
					teamSizes[smallestTeamIndex].numPlayers++;
				}
			}
		} else {
			// not team mode

			updatePlayers((players) => {
				for (const [key, player] of players) {
					delete player.teamId;
				}
			});

			updateTeams((teams) => {
				teams.clear();
			});
		}
	}, [connectedPlayersArray, disconnectedPlayersArray, teamMode, teams, updatePlayer, updatePlayers, updateTeams]);

	const mutePlayerNamesRef = useRef(mutePlayerNames);
	useEffect(() => void (mutePlayerNamesRef.current = mutePlayerNames), [mutePlayerNames]);

	const statusWithProgress = useStatusWithProgressStore((state) => state);
	const updateStatusWithProgress = useCallback((recipe) => {
		useStatusWithProgressStore.getState().set(recipe);
	}, []);
	const started = statusWithProgress.name !== PLAY_STATUS_LOBBY;

	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const user = useAuthStore((state) => state.user);
	const updateUserCache = useUserCacheStore((state) => state.update);

	const quickMode = usePlayStore((state) => state.quickMode);
	const setQuickMode = usePlayStore((state) => state.setQuickMode);
	const aiMode = usePlayStore((state) => state.aiMode);
	const setAiMode = usePlayStore((state) => state.setAiMode);
	const aiGenerating = usePlayStore((state) => state.aiGenerating);
	const setAiGenerating = usePlayStore((state) => state.setAiGenerating);

	const roomId = useWebSocketStore((state) => state.roomId);
	const roomJoined = useWebSocketStore((state) => state.roomJoined);
	const connected = useWebSocketStore((state) => state.connected);

	const [isAIGeneratedQuiz, setIsAIGeneratedQuiz] = useState(false);
	const [aiGeneratingInfo, setAIGeneratingInfo] = useState(null);

	const lobbyRef = useRef(null);
	const forceStartTimeout = useRef(null);

	const [latePlayer, setLatePlayer] = useState(null);
	const latePlayerRef = useRef(null);

	const originalQuiz = useRef(null);

	useEffect(() => {
		if (connected && roomId && roomJoined) {
			useWebSocketStore.getState().sendRoomSettings({ [ROOM_SETTINGS_OPEN]: !!joinOpen });
		}
	}, [connected, roomId, roomJoined, joinOpen]);

	const filterSlides = useCallback((filteredQuiz) => {
		originalQuiz.current = filteredQuiz;
		if (useSettingsStore.getState().excludeYoutube && filteredQuiz.slides) {
			const quiz = cloneDeep(filteredQuiz);
			for (let i = quiz.slides.length - 1; i >= 0; i--) {
				const slide = quiz.slides[i];
				if (slide.media && slide.media.source.startsWith("youtube")) {
					quiz.slides.splice(i, 1);
				}
			}

			return quiz;
		} else {
			//Count to see if youtube options is available
			let count = 0;
			for (let i = 0; i < filteredQuiz.slides?.length; i++) {
				const slide = filteredQuiz.slides[i];
				if (slide.media && slide.media.source.startsWith("youtube")) {
					count++;
				}
			}

			if (count > 0 && count === filteredQuiz.slides?.length) {
				setDisableYoutubeSetting(true);
			} else {
				setDisableYoutubeSetting(false);
			}

			return filteredQuiz;
		}
	}, []);

	useEffect(() => {
		if (originalQuiz.current && !quickMode) {
			const filteredQuiz = filterSlides(originalQuiz.current);
			setQuiz(filteredQuiz);
		}
	}, [filterSlides, excludeYoutube, setQuiz, quickMode]);

	const randomizeAnswers = useCallback((quiz) => {
		// Randomize answers
		for (const slide of quiz.slides) {
			if (slide.type === SLIDE_TYPE_REORDER) {
				for (let i = 0; i < slide.answers.length; i++) {
					slide.answers[i].pos = i;
				}
				slide.answers = shuffle(slide.answers);
			} else if ([SLIDE_TYPE_CLASSIC, SLIDE_TYPE_CHECK_BOXES].includes(slide.type)) {
				slide.answers.sort((a, b) => a.text.localeCompare(b.text));
			}
		}
		return quiz;
	}, []);

	const loadQuiz = useCallback(
		(loadedQuiz) => {
			if (mountedRef.current) {
				if (loadedQuiz) {
					loadedQuiz = filterSlides(loadedQuiz);
					loadedQuiz = randomizeAnswers(loadedQuiz);
					setQuiz(loadedQuiz);
				}
			}
		},
		[filterSlides, mountedRef, randomizeAnswers, setQuiz]
	);

	const aiError = useAIStore((state) => state.error);
	const aiErrorReason = useAIStore((state) => state.reason);
	const aiQuery = useAIStore((state) => state.query);
	const aiLanguage = useAIStore((state) => state.language);
	const aiQuestions = useAIStore((state) => state.questions);
	const aiImages = useAIStore((state) => state.images);
	const aiProgress = useAIStore((state) => state.progress);
	const aiJobId = useAIStore((state) => state.jobId);
	// const aiProcessTime = useAIStore((state) => state.processTime);
	const aiProcessTime = useMemo(
		() => ({
			questions: 120000,
			media: 7000,
		}),
		[]
	);

	const aiBadTopic = aiErrorReason === GENERATOR_REASON_BAD_TOPIC;

	const aiQueryRequestRef = useRef(null);
	useEffect(
		() =>
			useAIStore.getState().set((draft) => {
				if (
					draft.query !== props.query ||
					draft.language !== props.language ||
					draft.questions !== props.questions ||
					draft.images !== props.images
				) {
					draft.error = false;
					draft.jobId = null;
					draft.progress = 0;
					draft.finished = false;
				}
				draft.query = props.query;
				draft.language = props.language;
				draft.questions = props.questions;
				draft.images = props.images;
			}),
		[props.language, props.questions, props.images, props.query]
	);

	useEffect(() => {
		setIsAIGeneratedQuiz(false);

		let mounted = true;
		if (aiJobId) {
			let socket = null;
			let pingInterval = null;
			let reconnectTimeout = null;
			const connect = () => {
				if (pingInterval) {
					clearInterval(pingInterval);
					pingInterval = null;
				}
				if (reconnectTimeout) {
					clearTimeout(reconnectTimeout);
					reconnectTimeout = null;
				}

				if (socket) {
					socket.onopen = socket.onmessage = socket.onerror = socket.onclose = null;
					socket.close();
					socket = null;
				}

				socket = new WebSocket(API_WEB_SOCKET_GATEWAY_URL);

				pingInterval = setInterval(() => {
					try {
						socket.send(
							JSON.stringify({
								event: "ping",
							})
						);
					} catch (error) {
						console.error(error);
					}
				}, API_WEB_SOCKET_PING_TIMEOUT);

				socket.onopen = () => {
					if (mounted) {
						// console.log("Connected", aiJobId);
						socket.send(
							JSON.stringify({
								event: "subscribe",
								data: { jobId: aiJobId },
							})
						);
						socket.onmessage = (event) => {
							if (mounted) {
								try {
									const message = JSON.parse(event.data);

									if (message.action === "status") {
										if (message.failed) {
											// console.log("Reason:", message.reason);

											useAIStore.getState().set((draft) => {
												draft.error = true;
												draft.reason = message.reason;
												draft.progress = message.progress;
												draft.finished = false;
											});

											setIsAIGeneratedQuiz(true);
											setAiGenerating(false);
											if (message.info) {
												setAIGeneratingInfo(message.info);
											}
										} else if (message.finished) {
											useAIStore.getState().set((draft) => {
												draft.error = !message.quizId;
												draft.progress = message.progress;
												draft.finished = true;
											});

											setIsAIGeneratedQuiz(true);
											setAiGenerating(false);
											if (message.info) {
												setAIGeneratingInfo(message.info);
											}

											if (message.quizId && routerRef.current) {
												routerRef.current.replace(`/play/${message.quizId}/`, undefined, {
													shallow: true,
												});
											}
										} else {
											if (message.quizMedia && !usePlayStore.getState().quiz) {
												usePlayStore
													.getState()
													.set(
														(draft) =>
															void (draft.quiz = { media: message.quizMedia, slides: [] })
													);
											}

											if (message.info) {
												setAIGeneratingInfo(message.info);
											}

											useAIStore.getState().set((draft) => {
												draft.progress = message.progress;
												draft.finished = false;
											});
										}
									}
								} catch (error) {
									console.error(error);
									setIsError(true);
								}
							}
						};
						socket.onclose = (event) => {
							if (pingInterval) {
								clearInterval(pingInterval);
								pingInterval = null;
							}
							if (reconnectTimeout) {
								clearTimeout(reconnectTimeout);
								reconnectTimeout = null;
							}
							socket.onopen = socket.onmessage = socket.onerror = socket.onclose = null;
							reconnectTimeout = setTimeout(() => {
								reconnectTimeout = null;
								connect();
							}, 2000);
						};

						socket.onerror = (event) => {
							if (pingInterval) {
								clearInterval(pingInterval);
								pingInterval = null;
							}
							if (reconnectTimeout) {
								clearTimeout(reconnectTimeout);
								reconnectTimeout = null;
							}
							socket.onopen = socket.onmessage = socket.onerror = socket.onclose = null;
							reconnectTimeout = setTimeout(() => {
								reconnectTimeout = null;
								connect();
							}, 2000);
						};
					}
				};
			};

			connect();

			return () => {
				mounted = false;
				if (socket) {
					try {
						if (pingInterval) {
							clearInterval(pingInterval);
							pingInterval = null;
						}
						if (reconnectTimeout) {
							clearTimeout(reconnectTimeout);
							reconnectTimeout = null;
						}
						socket.onopen = socket.onmessage = socket.onerror = socket.onclose = null;
						socket.close();
						socket = null;
					} catch (error) {
						console.error(error);
					}
				}
			};
		}
	}, [aiQuery, aiJobId, setAiGenerating]);

	useEffect(() => {
		/*
		console.log(
			`Query: ${aiQuery}, Language: ${aiLanguage}, Finished: ${useAIStore.getState().finished}, Id: ${
				useAIStore.getState().jobId
			}`
		);
		*/

		if (aiQuery) {
			const aiJobId = useAIStore.getState().jobId;
			if (aiJobId) {
				setAiGenerating(!useAIStore.getState().finished);
				return;
			}
		}

		if (aiQuery && aiQuery !== aiQueryRequestRef.current) {
			aiQueryRequestRef.current = aiQuery;

			if (aiQuery) {
				useAIStore.getState().set((draft) => {
					draft.error = false;
					draft.jobId = null;
					draft.progress = 0;
					draft.finished = false;
				});

				let mounted = true;
				setAiGenerating(true);

				// console.log("Generate Quiz");

				const data = { query: aiQuery };
				if (aiLanguage) {
					data.language = aiLanguage;
				}
				if (aiQuestions) {
					data.numQuestions = aiQuestions;
				}
				if (aiImages) {
					data.generateImages = aiImages;
				}

				const generate = () => {
					apiGenerateQuiz(data)
						.then(({ jobId, quizId }) => {
							if (jobId) {
								// console.log(jobId);
								useAIStore.getState().set((draft) => {
									draft.error = false;
									draft.jobId = jobId;
									draft.progress = 0;
									draft.finished = false;
								});
							} else {
								if (quizId) {
									useAIStore.getState().set((draft) => {
										draft.error = false;
										draft.jobId = null;
										draft.progress = 0;
										draft.finished = true;
									});

									setIsAIGeneratedQuiz(true);
									setAiGenerating(false);

									if (routerRef.current) {
										routerRef.current.replace(`/play/${quizId}/`, undefined, {
											shallow: true,
										});
									}
								} else {
									console.error("Invalid jobId", jobId);

									useAIStore.getState().set((draft) => {
										draft.error = true;
										draft.jobId = null;
										draft.progress = 0;
										draft.finished = false;
									});

									if (mounted) {
										setIsError(true);
									}
								}
							}
						})
						.catch((error) => {
							console.error(error);
							if (mounted) {
								setIsError(true);
							}
						});
				};

				if (!props.boostGuardDisabled) {
					boostGuard((status) => {
						if (status === BOOST_STATUS_FREE || status === BOOST_STATUS_SUBSCRIBED) {
							generate();
						} else {
							if (mounted) {
								setIsError(true);
							}
						}
					});
				} else {
					generate();
				}

				return () => void (mounted = false);
			} else {
				useAIStore.getState().set((draft) => {
					draft.error = false;
					draft.jobId = null;
					draft.progress = 0;
					draft.finished = false;
				});
			}
		}
	}, [aiQuery, aiLanguage, setAiGenerating, props.boostGuardDisabled, boostGuard, aiQuestions, aiImages]);

	const onConfirmErrorGenerating = useCallback(() => {
		aiQueryRequestRef.current = null;

		useAIStore.getState().set((draft) => {
			draft.query = props.query;
			draft.language = props.language;
			draft.questions = props.questions;
			draft.images = props.images;
			draft.error = false;
			draft.jobId = null;
			draft.progress = null;
			draft.finished = false;
		});
	}, [props.language, props.questions, props.images, props.query]);

	const onCancelErrorGenerating = useCallback(() => {
		router.push(`/`, undefined, { shallow: true });
	}, [router]);

	useEffect(() => {
		latePlayerRef.current = latePlayer;
		let timeout = null;

		if (latePlayer) {
			timeout = setTimeout(() => {
				timeout = null;
				setLatePlayer(null);
			}, 2500);
		}

		return () => {
			if (timeout) {
				clearTimeout(timeout);
				timeout = null;
			}
		};
	}, [latePlayer]);

	const previewSlideIndex = props.previewSlideIndex;
	useEffect(() => {
		if (isFinite(previewSlideIndex)) {
			usePlayStore.getState().preview(previewSlideIndex);
			useStatusWithProgressStore.getState().set((draft) => {
				draft.name = PLAY_STATUS_GAME_START;
				draft.duration = 1;
				draft.progress = 0;
				draft.elapsedTime = 0;
			});
		}
	}, [previewSlideIndex]);

	const [typeAnswerState, updateTypeAnswerState] = useImmer({
		value: "",
		isClose: null,
		isCorrect: null,
		isNumeric: false,
		wrongAnswers: [],
	});

	// This object is used to prefetch data for the upcoming slide
	const nextSlide = useMemo(() => {
		const i = statusWithProgress.name === PLAY_STATUS_LOBBY ? 0 : slideIndex + 1;
		return quiz?.slides?.length >= 1 &&
			(statusWithProgress.name === PLAY_STATUS_LOBBY || (slideIndex >= 0 && slideIndex <= quiz.slides.length - 2))
			? pick(toSlideObject(quiz.slides[i], i), [
					"id",
					"type",
					"media",
					"funFactMedia",
					"question",
					"questionVoice",
					"answers",
					"answerVoice",
			  ])
			: null;
	}, [quiz?.slides, slideIndex, statusWithProgress?.name]);

	const previewMode = usePlayStore((state) => state.previewMode);

	const doublePoints = useMemo(
		() =>
			!previewMode && quiz?.slides?.findLastIndex((slide) => slide.type !== SLIDE_TYPE_INFO_SLIDE) === slideIndex,
		[previewMode, quiz?.slides, slideIndex]
	);

	const voteMode = usePlayStore((state) => state.voteMode);
	const setVoteMode = usePlayStore((state) => state.setVoteMode);

	const [voteMap, updateVoteMap] = useImmer({}); // quizId: [connectionId, connectionId, ...]

	const [voteState, updateVoteState] = useImmer({
		numberOfVotes: {},
		avatars: {},
		numConnectedPlayers: 0,
	});

	const reduceVoteState = useCallback(() => {
		updateVoteState((draft) => {
			for (const quizId in voteMap) {
				const connectionIds = voteMap[quizId] || [];

				if (!draft.numberOfVotes) {
					draft.numberOfVotes = {};
				}
				draft.numberOfVotes[quizId] = connectionIds.length;

				const showConnectionIds = reverse(connectionIds.slice(-8));
				const avatars = [];

				for (const connectionId of showConnectionIds) {
					const player = players.get(connectionId);
					const team = teamMode && teams.get(player?.teamId);

					const avatar = team?.avatar ?? player?.avatar;
					if (avatar) {
						avatars.unshift(avatar);
					}
				}

				if (!draft.avatars) {
					draft.avatars = {};
				}
				draft.avatars[quizId] = avatars.filter(Boolean);
			}

			draft.numConnectedPlayers = getConnectedPlayers([...players.values()]).length;
		});
	}, [players, teams, updateVoteState, voteMap, teamMode]);
	const reduceVoteStateRef = useRef(reduceVoteState);
	useEffect(() => void (reduceVoteStateRef.current = reduceVoteState), [reduceVoteState]);

	const previousReduceVoteStateRef = useRef(Date.now());
	useEffect(() => {
		const now = Date.now();
		const diff = Math.min(
			Math.max(VOTE_THROTTLE_TIMEOUT - (now - previousReduceVoteStateRef.current), 0),
			VOTE_THROTTLE_TIMEOUT
		);

		let timeout = null;
		if (diff >= VOTE_STATE_UPDATE_THROTTLE) {
			previousReduceVoteStateRef.current = now;
			if (reduceVoteStateRef.current) {
				reduceVoteStateRef.current();
			}
		} else {
			timeout = setTimeout(() => {
				timeout = null;
				previousReduceVoteStateRef.current = Date.now();
				if (reduceVoteStateRef.current) {
					reduceVoteStateRef.current();
				}
			}, diff);
		}

		return () => {
			if (timeout) {
				clearTimeout(timeout);
				timeout = null;
			}
		};
	}, [voteMap]);

	const [resetTimerActive, setResetTimerActive] = useState(false);
	const [myVoteQuizId, setMyVoteQuizId] = useState(null);

	const mapTarget = useMemo(() => {
		const slide = quiz?.slides[slideIndex];
		if (slide?.type === SLIDE_TYPE_LOCATION) {
			return deserialize(slide?.answers)?.target;
		}
		return null;
	}, [quiz?.slides, slideIndex]);

	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [sessionId, setSessionId] = useState(null);

	const { placeCode, placeType } = useMemo(() => mapTarget || {}, [mapTarget]);
	const correctAnswerFeature = useGeoFeature({ placeCode, condition: !!(roomId && roomJoined && !isLoading) });

	const countryCode = useMemo(
		() => placeCode?.includes(PLACE_CODE_SEPARATOR) && placeCode?.split(PLACE_CODE_SEPARATOR)[0],
		[placeCode]
	);

	const { getVisibility } = useStatusSequence(quiz?.slides[slideIndex]?.type, statusWithProgress);
	const sendPlace = correctAnswerFeature && getVisibility(PLAY_STATUS_ALL_ANSWERS_RECEIVED);

	const insertVote = useCallback(
		(quizId, connectionId, numberOfVotes = 1) => {
			if (!isPaused && statusWithProgress.name === PLAY_STATUS_VOTE_SHOW) {
				updateVoteMap((draft) => {
					if (voteMode !== VOTE_MODE_CRAZY_CLICK_MODE) {
						setStateEnded(false);
						// delete player's previous vote
						for (const quizId in draft) {
							const i = draft[quizId].indexOf(connectionId);
							if (i >= 0) {
								draft[quizId].splice(i, 1);
							}
						}
					}

					//Ignore votes from guests in host decides mode
					if (voteMode === VOTE_MODE_HOST_DECIDES && connectionId !== PLAYING_HOST_CONNECTION_ID) {
						return;
					}

					for (let i = 0; i < numberOfVotes; i++) {
						if (isArray(draft[quizId])) {
							draft[quizId].push(connectionId);
						} else {
							draft[quizId] = [connectionId];
						}
					}
				});
			}
		},
		[isPaused, statusWithProgress.name, updateVoteMap, voteMode]
	);
	const insertVoteRef = useRef(insertVote);
	useEffect(() => void (insertVoteRef.current = insertVote), [insertVote]);

	useEffect(() => {
		if (resetTimerActive) {
			let timeout = setTimeout(() => {
				timeout = null;
				setResetTimerActive(false);
			}, 300);
			return () => {
				if (timeout) {
					clearTimeout(timeout);
					timeout = null;
				}
			};
		}
	}, [resetTimerActive, setResetTimerActive]);

	// Here are some parts of guest state which we don't want to recalculate too often
	const guestStateNextSlide = useMemo(() => nextSlide && omit(nextSlide, ["answers", "answerVoice"]), [nextSlide]);
	const guestStateQuiz = useMemo(() => getQuizForSending(quiz), [quiz]);
	const guestStatePlayers = useMemo(() => [...players.values()], [players]); // TODO: filter private properties
	const guestStateSlideObject = useMemo(
		() =>
			quiz && slideIndex >= 0 && PLAY_STATUES_SLIDE.includes(statusWithProgress.name)
				? toSlideObject(quiz.slides[slideIndex], slideIndex)
				: null,
		[quiz, slideIndex, statusWithProgress.name]
	);
	const guestStateSettings = useMemo(
		() => ({
			safeNames,
			voiceOverride,
			hideIncorrectTypeAnswers,
			showCode,
			mute: muteGuest,
			numQuestionsPerRound,
			numRoundsPerGame,
			joinOpen,
			backgroundAnimationEnabled,
			mutePlayerNames,
			reducedMotion,
			hidePlayerCountry,
			teamMode,
		}),
		[
			backgroundAnimationEnabled,
			hideIncorrectTypeAnswers,
			hidePlayerCountry,
			joinOpen,
			muteGuest,
			mutePlayerNames,
			numQuestionsPerRound,
			numRoundsPerGame,
			reducedMotion,
			safeNames,
			showCode,
			teamMode,
			voiceOverride,
		]
	);
	const guestStateTeams = useMemo(() => [...teams.values()], [teams]);

	const guestState = useMemo(
		() => ({
			isPaused,
			leaderboard, // TODO: Can be derived from players at guest
			nextQuiz: nextQuizId,
			nextSlide: guestStateNextSlide,
			players: guestStatePlayers,
			teams: guestStateTeams,
			doublePoints,
			quickMode,
			quiz: guestStateQuiz,
			recommendedQuizzes,
			settings: guestStateSettings,
			slideIndex,
			slideObject: guestStateSlideObject,
			typeAnswerWrongAnswers: typeAnswerState.wrongAnswers,
			numberOfVotes: voteState.numberOfVotes,
			voteAvatars: voteState.avatars,
			voteMode,
			aiQuery,
			aiMode,
			aiGenerating,
			aiGeneratingInfo,
			aiGeneratingProgress: aiProgress,
			aiProcessTime: aiProcessTime,
			placeCode: sendPlace ? mapTarget?.placeCode : undefined,
			placeType: mapTarget?.placeType,
			countryCode,
		}),
		[
			aiGenerating,
			aiGeneratingInfo,
			aiMode,
			aiProcessTime,
			aiProgress,
			aiQuery,
			countryCode,
			doublePoints,
			guestStateNextSlide,
			guestStatePlayers,
			guestStateQuiz,
			guestStateSettings,
			guestStateSlideObject,
			guestStateTeams,
			isPaused,
			leaderboard,
			mapTarget?.placeCode,
			mapTarget?.placeType,
			nextQuizId,
			quickMode,
			recommendedQuizzes,
			sendPlace,
			slideIndex,
			typeAnswerState.wrongAnswers,
			voteMode,
			voteState.avatars,
			voteState.numberOfVotes,
		]
	);

	const updatePlayerName = usePlayerStore((state) => state.updateName);
	const updatePlayerSafeName = usePlayerStore((state) => state.updateSafeName);
	const updatePlayerNameAndSafeName = usePlayerStore((state) => state.updateNameAndSafeName);
	const updatePlayerAvatar = usePlayerStore((state) => state.updateAvatar);
	const updatePlayerCountry = usePlayerStore((state) => state.updateCountry);

	const playerName = usePlayerStore((state) => state.name);
	const playerSafeName = usePlayerStore((state) => state.safeName);
	const playerAvatar = usePlayerStore((state) => state.avatar);
	const playerCountry = usePlayerStore((state) => state.country);

	const country = useWebSocketStore((state) => state.country);

	const [playerState, updatePlayerState] = useImmer({
		name: "",
		avatar: null,
		country: null,
	});

	useEffect(() => {
		if (safeNames) {
			let safeName = getInitialAvatarSafeName();
			const name = getInitialAvatarName();
			if (getVerifiedAvatarSafeName(name)) {
				updatePlayerNameAndSafeName(name, name);
			} else {
				if (!isString(safeName)) {
					safeName = getRandomAvatarName();
				}
				updatePlayerSafeName(safeName);
			}
		} else {
			let name = getInitialAvatarName();
			if (getVerifiedAvatarSafeName(name)) {
				updatePlayerNameAndSafeName(name, name);
			} else {
				if (!isString(name)) {
					name = getRandomAvatarName();
				}
				updatePlayerName(name);
			}
		}
	}, [safeNames, updatePlayerName, updatePlayerNameAndSafeName, updatePlayerSafeName]);

	useEffect(() => {
		updatePlayerState((draft) => void (draft.name = safeNames ? playerSafeName : playerName));
	}, [safeNames, playerName, playerSafeName, updatePlayerState]);

	useEffect(() => {
		const avatar = getInitialAvatar();
		updatePlayerAvatar(avatar);
	}, [updatePlayerAvatar]);

	useEffect(() => {
		if (isArray(playerAvatar)) {
			const avatar = cloneDeep(playerAvatar);
			const { promise, abort } = apiUploadAvatar(avatar);

			promise
				.then(({ filename }) => {
					if (filename) {
						updatePlayerState((draft) => void (draft.avatar = filename));
					} else {
						updatePlayerState((draft) => void (draft.avatar = avatar));
					}
				})
				.catch((err) => {
					if (err.name !== "AbortError") {
						updatePlayerState((draft) => void (draft.avatar = avatar));
					}
				});

			return () => void abort();
		}
	}, [playerAvatar, updatePlayerState]);

	useEffect(() => {
		const abortFunctions = [];
		for (const [_id, team] of teams) {
			if (team.avatarLayers && !team.avatar) {
				const { promise, abort } = apiUploadAvatar(team.avatarLayers);
				promise
					.then(({ filename }) => {
						if (filename) {
							updateTeams((teams) => {
								const myTeam = teams.get(team.connectionId);
								if (myTeam) {
									myTeam.avatar = filename;
								}
							});
						}
					})
					.catch((err) => {
						if (err.name !== "AbortError") {
							console.error(err);
						}
					});

				abortFunctions.push(abort);
			}
		}

		return () => {
			for (const func of abortFunctions) {
				func();
			}
		};
	}, [teams, updateTeams]);

	useEffect(() => void updatePlayerCountry(country), [country, updatePlayerCountry]);

	useEffect(
		() => void updatePlayerState((draft) => void (draft.country = playerCountry)),
		[playerCountry, updatePlayerState]
	);

	useEffect(() => {
		if (haveLocalPlayer) {
			updatePlayer(PLAYING_HOST_CONNECTION_ID, (player) => {
				if (player) {
					player.name = playerState.name;
				}
			});
		}
	}, [safeNames, haveLocalPlayer, playerState.name, updatePlayer]);

	useEffect(() => {
		if (haveLocalPlayer) {
			updatePlayer(PLAYING_HOST_CONNECTION_ID, (player) => {
				if (player) {
					player.avatar = playerState.avatar;
				}
			});
		}
	}, [safeNames, haveLocalPlayer, playerState.avatar, updatePlayer]);

	useEffect(() => {
		if (haveLocalPlayer) {
			updatePlayer(PLAYING_HOST_CONNECTION_ID, (player) => {
				if (player) {
					player.country = playerState.country;
				}
			});
		}
	}, [safeNames, haveLocalPlayer, playerState.country, updatePlayer]);

	useEffect(() => {
		if (!connected || !roomId || !roomJoined) {
			return;
		}

		const sentGuestState = usePlayStore.getState().sentGuestState;
		if (isEmpty(sentGuestState)) {
			useWebSocketStore.getState().sendRoomMessage({ state: guestState });
			usePlayStore.getState().set((state) => void (state.sentGuestState = cloneDeep(guestState)));
		} else {
			const patch = jsonpatch.compare(sentGuestState, guestState);
			if (patch.length > 0) {
				useWebSocketStore.getState().sendRoomMessage({ patch });
				usePlayStore.getState().set((state) => void (state.sentGuestState = cloneDeep(guestState)));
			}
		}
	}, [guestState, connected, roomId, roomJoined, statusWithProgress.name, statusWithProgress.duration]);

	/*
	useEffect(() => {
		if (!connected || !roomId || !roomJoined) {
			return;
		}

		const onVisibilityChange = () => {
			if (!document.hidden) {
				// console.log("Send guest state!");
				useWebSocketStore.getState().sendRoomMessage({ state: guestState });
				usePlayStore.getState().set((state) => void (state.sentGuestState = cloneDeep(guestState)));
			}
		};
		document.addEventListener("visibilitychange", onVisibilityChange);
		return () => void document.removeEventListener("visibilitychange", onVisibilityChange);
	}, [connected, guestState, roomId, roomJoined]);
	*/

	useEffect(() => {
		if (!connected || !roomId || !roomJoined) {
			return;
		}

		useWebSocketStore.getState().sendRoomMessage({
			status: statusWithProgress.name,
			duration: (statusWithProgress.duration || 0).toString(),
		});
	}, [statusWithProgress.name, statusWithProgress.duration, connected, roomId, roomJoined]);

	useEffect(() => {
		if (!quickMode && statusWithProgress.name === PLAY_STATUS_SHOW_RATE) {
			// Reset players' ratings when we enter the rating state
			for (const [connectionId] of usePlayStore.getState().players) {
				usePlayStore.getState().updatePlayer(connectionId, (player) => {
					player.ratings = {};
				});
			}
		}
	}, [quickMode, statusWithProgress.name]);

	useStatusWithProgress(statusWithProgress, updateStatusWithProgress, isPaused);

	const instructorPaced = useSettingsStore((state) => !!state.instructorPaced);
	const skipToNextSlide = usePlayStore((state) => state.skipToNextSlide);
	const addSkippedSlide = usePlayStore((state) => state.addSkippedSlide);

	const slideSkipToNextEnabled = SLIDE_SKIP_STATUSES.includes(statusWithProgress.name);

	const showContinue = useMemo(() => {
		switch (statusWithProgress.name) {
			case PLAY_STATUS_SHOW_INSTRUCTOR_PACED:
				return instructorPaced && slideSkipToNextEnabled;
			case PLAY_STATUS_SHOW_FUN_FACT: {
				const funFactMedia = quiz?.slides[slideIndex]?.funFactMedia;
				// For now we _always_ show the continue button on youtube funfact, so the user can continue if the video doesn't play.
				return funFactMedia?.source && funFactMedia?.source.startsWith("youtube/");
				// && (!isFinite(funFactMedia?.trim.duration) || funFactMedia?.trim.loop)
			}
			default:
				return false;
		}
	}, [instructorPaced, quiz?.slides, slideIndex, slideSkipToNextEnabled, statusWithProgress.name]);

	const loadAudio = useAudioStore((state) => state.loadAudio);
	const pauseMusic = useAudioStore((state) => state.pauseMusic);
	const resumeMusic = useAudioStore((state) => state.resumeMusic);
	const fadeInMusic = useAudioStore((state) => state.fadeInMusic);
	const fadeOutMusic = useAudioStore((state) => state.fadeOutMusic);
	const stopAudio = useAudioStore((state) => state.stopAudio);
	const setLobbyMusic = useAudioStore((state) => state.setLobbyMusic);

	useEffect(
		() => void setLobbyMusic(statusWithProgress.name === PLAY_STATUS_LOBBY),
		[statusWithProgress.name, setLobbyMusic]
	);

	const submittedAnswer = usePlayStore((state) =>
		state.players.has(PLAYING_HOST_CONNECTION_ID) &&
		Object.prototype.hasOwnProperty.call(state.players.get(PLAYING_HOST_CONNECTION_ID), "selectedAnswer")
			? state.players.get(PLAYING_HOST_CONNECTION_ID).selectedAnswer
			: null
	);

	const submittedAnswerProgress = usePlayStore((state) =>
		state.players.has(PLAYING_HOST_CONNECTION_ID) &&
		Object.prototype.hasOwnProperty.call(state.players.get(PLAYING_HOST_CONNECTION_ID), "answerProgress")
			? state.players.get(PLAYING_HOST_CONNECTION_ID).answerProgress
			: null
	);

	const pausedTimelineRef = useRef(null);

	const stopHosting = useStopHosting();
	const stopHostingRef = useRef(stopHosting);
	useEffect(() => void (stopHostingRef.current = stopHosting), [stopHosting]);

	const [teamAvatarCustomizeActive, setTeamAvatarCustomizeActive] = useState(null);
	const [avatarCustomizeActive, setAvatarCustomizeActive] = useState(false);
	const [localJoinConfirm, setLocalJoinConfirm] = useState(false);
	const [autoStartAfterAvatarCustomize, setAutoStartAfterAvatarCustomize] = useState(false);

	useEffect(() => {
		if (statusWithProgress.name === PLAY_STATUS_ALL_ANSWERS_RECEIVED) {
			const slide = quiz?.slides[slideIndex];

			//Don't save information if logged in user playing own quiz alone
			if (
				usePlayStore.getState().players.size === 1 &&
				usePlayStore.getState().players.get(PLAYING_HOST_CONNECTION_ID) &&
				slide?.quiz?.owner?.id === useAuthStore.getState().user?.id
			) {
				return;
			}

			if (usePlayStore.getState().previewMode) {
				// Don't save stats in preview mode
				return;
			}

			if (slide && slide.type !== SLIDE_TYPE_INFO_SLIDE) {
				let numCorrect = 0;
				let numIncorrect = 0;
				let numUnanswered = 0;
				for (const [, player] of usePlayStore.getState().players) {
					if (isPlayerConnected(player)) {
						if (slide.type === SLIDE_TYPE_TYPE_ANSWER) {
							if (player.correctness === 1.0) {
								numCorrect++;
							} else if (player.wrongTypeAnswerSubmitted === slideIndex) {
								numIncorrect++;
							} else {
								numUnanswered++;
							}
						} else {
							if (!(slideIndex in player.history)) {
								numUnanswered++;
							} else if (player.correctness === 0) {
								numIncorrect++;
							} else {
								numCorrect++;
							}
						}
					}
				}
				apiPostSlideResults({ slideId: slide.id, numCorrect, numIncorrect, numUnanswered });
			}
		}
	}, [quiz?.slides, slideIndex, statusWithProgress.name]);

	useEffect(() => {
		const savePlayedSlide = async (id) => {
			await apiPostSavePlayedSlide({ slideId: id });
		};

		if (quickMode && slideIndex >= 0 && isAuthenticated) {
			try {
				const id = quiz.slides[slideIndex]?.id;
				if (id) {
					savePlayedSlide(id);
				}
			} catch (error) {
				//Do nothing
			}
		}
	}, [slideIndex, quickMode, quiz?.slides, isAuthenticated]);

	const setMusicVolume = useAudioStore((state) => state.setMusicVolume);
	const musicVolume = useSettingsStore((state) => state.musicVolume);
	const musicVolumeRef = useRef(musicVolume);
	useEffect(() => void (musicVolumeRef.current = musicVolume), [musicVolume]);

	const setSoundVolume = useAudioStore((state) => state.setSoundVolume);
	const soundVolume = useSettingsStore((state) => state.soundVolume);
	const soundVolumeRef = useRef(soundVolume);
	useEffect(() => void (soundVolumeRef.current = soundVolume), [soundVolume]);

	const setVoiceVolume = useAudioStore((state) => state.setVoiceVolume);
	const voiceVolume = useSettingsStore((state) => state.voiceVolume);
	const voiceVolumeRef = useRef(voiceVolume);
	useEffect(() => void (voiceVolumeRef.current = voiceVolume), [voiceVolume]);

	useEffect(() => {
		setMusicVolume(musicVolumeRef.current);
		setSoundVolume(soundVolumeRef.current);
		setVoiceVolume(voiceVolumeRef.current);
	}, [setMusicVolume, setSoundVolume, setVoiceVolume]);

	const gameStartedFlag = usePlayStore((state) => state.gameStartedFlag);

	useEffect(() => {
		if (statusWithProgress.name === PLAY_STATUS_GAME_START && !previewMode && !gameStartedFlag) {
			(async () => {
				try {
					const startPlayers = getConnectedPlayers([...usePlayStore.getState().players.values()]).length;

					apiCreateSession({
						quizId: quickMode ? null : props.quizId,
						startPlayers,
					})
						.then((response) => {
							setSessionId(response?.id);
						})
						.catch((error) => {
							console.error(error);
						});

					usePlayStore.getState().set((draft) => void (draft.gameStartedFlag = true));

					if (user) {
						updateUserCache(
							(draft) => void draft.quizzesPlayed.set(props.quizId, new Date().toISOString())
						);
					}
				} catch (err) {
					console.error(err);
				}
			})();
		}
	}, [gameStartedFlag, previewMode, props.quizId, quickMode, statusWithProgress.name, updateUserCache, user]);

	const [leaveURL, setLeaveURL] = useState(null);
	const leaveURLRef = useRef(leaveURL);
	useEffect(() => void (leaveURLRef.current = leaveURL), [leaveURL]);

	const onRouteChangeStart = useCallback((url, { shallow }) => {
		if (!isString(leaveURLRef.current)) {
			let isPlayQuizPage = false;
			let isAIPage = false;
			if (url && url.startsWith("/play/")) {
				if (routerRef.current) {
					const split = routerRef.current.asPath.split("/");
					if (split.length >= 3) {
						const page = split[2];
						if (page && (isUUID(page) || page === "vote" || page === "ai")) {
							isPlayQuizPage = true;
							if (page === "ai") {
								isAIPage = true;
							}
						}
					}
				}
			}
			if (isPlayQuizPage) {
				setLeaveURL(null);
			} else {
				if (useStatusWithProgressStore.getState().name === PLAY_STATUS_LOBBY) {
					// Enable leave dialog if there's any connected non-host player
					const numberOfConnectedPlayer = playersRef.current
						? getConnectedPlayers([...playersRef.current.values()], true).length
						: 0;
					if (numberOfConnectedPlayer === 0) {
						setLeaveURL(null);
						if (stopHostingRef.current) {
							stopHostingRef.current();
						}
						// Empty AI store
						if (!isAIPage) {
							useAIStore.getState().reset();
						}
						return;
					}
				}

				const numberOfConnectedPlayerIncludingLocal = playersRef.current
					? getConnectedPlayers([...playersRef.current.values()], false).length
					: 0;
				if (numberOfConnectedPlayerIncludingLocal >= 1) {
					setLeaveURL(url);
					Router.events.emit("routeChangeError");
					throw "Abort route change. Please ignore this error.";
				} else {
					setLeaveURL(null);
					if (stopHostingRef.current) {
						stopHostingRef.current();
					}
					// Empty AI store
					if (!isAIPage) {
						useAIStore.getState().reset();
					}
				}
			}
		}
	}, []);

	const onRouteChangeStartRef = useRef(onRouteChangeStart);
	useEffect(() => void (onRouteChangeStart.current = onRouteChangeStart), [onRouteChangeStart]);

	useEffect(() => {
		if (routerRef.current && onRouteChangeStartRef.current) {
			const onRouteChangeStart = onRouteChangeStartRef.current;
			const router = routerRef.current;
			router.events.on("routeChangeStart", onRouteChangeStart);
			return () => void router.events.off("routeChangeStart", onRouteChangeStart);
		}
	}, []);

	// Reset sentGuestState on unmount
	useEffect(() => () => void usePlayStore.getState().set((state) => void (state.sentGuestState = {})), []);

	const gameEndedFlag = usePlayStore((state) => state.gameEndedFlag);

	useEffect(() => {
		if (statusWithProgress.name === PLAY_STATUS_SHOW_WINNER && !previewMode && !gameEndedFlag) {
			const endPlayers = getConnectedPlayers([...usePlayStore.getState().players.values()]).length;

			if (sessionId) {
				apiUpdateSession({ sessionId, endPlayers }).catch((err) => void console.error(err));
				usePlayStore.getState().set((draft) => void (draft.gameEndedFlag = true));
			}
		}
	}, [gameEndedFlag, previewMode, sessionId, statusWithProgress.name]);

	useEffect(() => {
		return () => {
			if (document.fullscreenElement && document.exitFullscreen) {
				document.exitFullscreen();
			}
		};
	}, []);

	useEffect(() => {
		return () => {
			if (stopAudio) {
				stopAudio();
			}
			sfx.unload();
		};
	}, [stopAudio]);

	useEffect(() => {
		const connected = useWebSocketStore.getState().connected;

		if (!connected) {
			return;
		}

		if (isPaused) {
			pausedTimelineRef.current = gsap.exportRoot();
			pausedTimelineRef.current.pause();

			sfx.pause();
			voicePlayer.pause();
			pauseMusic();
		} else {
			sfx.play();
			voicePlayer.resume();
			resumeMusic();
		}

		return () => {
			const connected = useWebSocketStore.getState().connected;

			if (connected) {
				if (pausedTimelineRef.current) {
					pausedTimelineRef.current.play();
					pausedTimelineRef.current = null;
				}
			}
		};
	}, [isPaused, pauseMusic, resumeMusic]);

	const quickModeRef = useRef(false);
	useEffect(() => void (quickModeRef.current = quickMode), [quickMode]);

	useEffect(() => {
		return () => {
			setSettingsStore((draft) => {
				draft.excludeYoutube = false;
			});
		};
	}, [setSettingsStore]);

	const [disableYoutubeSetting, setDisableYoutubeSetting] = useState(false);

	useEffect(() => {
		if (props.quizId && props.quizId === QUIZ_VOTEMODE_ID) {
			setQuickMode(true);
			setAiMode(false);
			setIsLoading(false);
			setDisableYoutubeSetting(false);
		} else if (props.quizId && props.quizId === QUIZ_AI_ID) {
			setQuickMode(false);
			setAiMode(true);
			setIsLoading(false);
		} else if (props.quizId) {
			setQuickMode(false);
			setIsLoading(true);
			setShowLoadingQuestions(true);
			apiGetQuiz(props.quizId)
				.then(
					(quiz) => {
						setAiMode(quiz.generated);
						loadQuiz(quiz);
					},
					() => {
						//Quiz not found
						setIsError(true);
					}
				)
				.finally(() => {
					setIsLoading(false);
					setShowLoadingQuestions(false);
					setIsAIGeneratedQuiz(false);
				});
		}
	}, [loadQuiz, props.quizId, setQuickMode, setAiMode, loadAudio]);

	useEffect(() => {
		if (roomId && roomJoined && !isLoading) {
			if (quickMode) {
				loadAudio(false);
			} else if (aiMode) {
				loadAudio(true);
			} else if (quiz) {
				loadAudio(quiz.generated);
			}
		}
	}, [aiMode, isLoading, loadAudio, quickMode, quiz, roomId, roomJoined]);

	useEffect(() => {
		if (statusWithProgress.name === PLAY_STATUS_GAME_START) {
			googleAnalyticsEvent(
				"quiz_start",
				"host",
				usePlayStore.getState().sentGuestState.quickMode ? "quick_mode" : usePlayStore.getState().quiz?.id,
				usePlayStore.getState().players ? usePlayStore.getState().players.size : 0
			);
		} else if (statusWithProgress.name === PLAY_STATUS_SHOW_WINNER) {
			googleAnalyticsEvent(
				"quiz_end",
				"host",
				usePlayStore.getState().sentGuestState.quickMode ? "quick_mode" : usePlayStore.getState().quiz?.id,
				usePlayStore.getState().players ? usePlayStore.getState().players.size : 0
			);
		}
	}, [statusWithProgress.name]);

	const onRoomJoin = useCallback(
		(message, callback = null) => {
			if (!message) {
				if (callback) {
					callback(null, message.connectionId);
				}
				return;
			}

			const { sentGuestState } = usePlayStore.getState();
			const statusWithProgress = useStatusWithProgressStore.getState();

			const { players } = usePlayStore.getState();
			let player = null;
			if (players.has(message.connectionId)) {
				player = players.get(message.connectionId);
			}

			const roomMessage = {
				state: sentGuestState,
				status: statusWithProgress.name,
				duration: statusWithProgress.duration,
				progress: statusWithProgress.progress,
			};

			if (player) {
				const team = teams.get(player.teamId);

				roomMessage.player = {
					name: player.name,
					avatar: player.avatar,
					reconnect: !!(player.name && player.avatar),
					points: team?.points ?? player.points,
					rank: team
						? isFinite(team.rank)
							? team.rank + 1
							: undefined
						: isFinite(player.rank)
						? player.rank + 1
						: undefined,
				};
			}

			(callback || useWebSocketStore.getState().sendRoomMessage)(roomMessage, message.connectionId);

			updatePlayer(message.connectionId, (player) => {
				// Update player *after* sending state, so patch is not sent before state.
				if (player) {
					player.spectating = !!message.spectating;
					player.disconnected = false;
				}
			});

			googleAnalyticsEvent("quiz_join", "host", message.connectionId);
		},
		[teams, updatePlayer]
	);
	const onRoomJoinRef = useRef(onRoomJoin);
	useEffect(() => void (onRoomJoinRef.current = onRoomJoin), [onRoomJoin]);

	const allPlayersHaveVoted = useMemo(() => {
		if (voteMode === VOTE_MODE_CRAZY_CLICK_MODE) {
			return false;
		}
		if (voteMode === VOTE_MODE_HOST_DECIDES) {
			if (myVoteQuizId) {
				return true;
			}
		} else {
			const connectedPlayers = getConnectedPlayers([...players.values()]);

			if (connectedPlayers.length === 1) {
				return connectedPlayers.every((player) =>
					Object.keys(voteMap).some((quizId) => voteMap[quizId].includes(player.connectionId))
				);
			}

			const activePlayers = connectedPlayers.filter((player) => !player.inactive);

			//Don't set flag if there are no active players
			if (activePlayers.length === 0) {
				return false;
			}

			return activePlayers.every((player) =>
				Object.keys(voteMap).some((quizId) => voteMap[quizId].includes(player.connectionId))
			);
		}
	}, [voteMode, myVoteQuizId, players, voteMap]);

	const rangeGrid = useMemo(() => {
		if (
			slideIndex >= 0 &&
			isArray(quiz?.slides) &&
			quiz.slides.length > slideIndex &&
			quiz.slides[slideIndex].type === SLIDE_TYPE_RANGE
		) {
			const answers = quiz.slides[slideIndex].answers;
			const [value] = answers[0].text.split(" ");
			const [min, max] = answers[1].text.split(",");
			return getGrid({ min, max, value });
		} else {
			return [];
		}
	}, [quiz?.slides, slideIndex]);
	const rangeGridRef = useRef([]);
	useEffect(() => void (rangeGridRef.current = rangeGrid), [rangeGrid]);

	const resetTimer = useCallback((mode, sound) => {
		useStatusWithProgressStore.getState().set((draft) => {
			draft.name = PLAY_STATUS_VOTE_SHOW;
			draft.progress = 0;
			draft.elapsedTime = 0;
		});

		useStatusWithProgressStore.getState().updateDuration(mode === VOTE_MODE_CRAZY_CLICK_MODE ? 15 : 25);
		useWebSocketStore.getState().sendRoomMessage({ resetTimer: true, sound: sound });
	}, []);

	const onNewVoteMode = useCallback(
		(newVoteMode) => {
			if (newVoteMode === voteMode) {
				return;
			}

			setVoteMode(newVoteMode);
			setStateEnded(false);
			setMyVoteQuizId(null);

			switch (newVoteMode) {
				case VOTE_MODE_NORMAL:
					resetTimer(newVoteMode, false);
					updateVoteMap((draft) => {
						for (const quizId in draft) {
							draft[quizId].splice(0);
						}
					});
					break;

				case VOTE_MODE_CRAZY_CLICK_MODE:
					resetTimer(newVoteMode, false);
					break;

				case VOTE_MODE_HOST_DECIDES:
					resetTimer(newVoteMode, false);
					updateVoteMap((draft) => {
						// delete all votes from all quizzes
						for (const quizId in draft) {
							draft[quizId].splice(0);
						}
					});
					break;
			}
		},
		[resetTimer, setVoteMode, updateVoteMap, voteMode]
	);

	const [playedVoiceClips, setPlayedVoiceClips] = useState({});

	const pinpointPaths = useMemo(() => {
		const slide = quiz?.slides?.[slideIndex];
		if (!slide || slide.type !== SLIDE_TYPE_PINPOINT) {
			return undefined;
		}
		return slide.answers.map(({ text }) => decodePath(text, 400, 300)); // width and height must be same as in PinpointSlide.jsx
	}, [quiz?.slides, slideIndex]);

	useEffect(() => {
		if (teamMode) {
			// update number of connected players in each teams
			const numPlayers = Object.fromEntries(Array.from(teams.values()).map((team) => [team.connectionId, 0]));
			for (const [connectionId, player] of players) {
				numPlayers[player.teamId]++;
			}
			updateTeams((teams) => {
				for (const [connectionId, team] of teams) {
					team.numPlayers = numPlayers[connectionId];
				}
			});
		}
	}, [players, teamMode, teams, updateTeams]);

	const movePlayer = useCallback(
		(fromConnectionId, toConnectionId, playerConnectionId) => {
			updatePlayer(playerConnectionId, (player) => void (player.teamId = toConnectionId));
		},
		[updatePlayer]
	);

	useEffect(() => {
		if (statusWithProgress.name === PLAY_STATUS_LOAD_SLIDE) {
			usePlayStore.getState().set((draft) => {
				draft.slideTimestamp = undefined;
			});
		} else if (statusWithProgress.name === PLAY_STATUS_WAIT_FOR_ANSWER) {
			usePlayStore.getState().set((draft) => {
				draft.slideTimestamp = Date.now();
				draft.pauseDuration = 0;
				draft.pauseTimestamp = undefined;
			});
		}
	}, [statusWithProgress.name]);

	useEffect(() => {
		if (isPaused) {
			usePlayStore.getState().set((draft) => {
				draft.pauseTimestamp = Date.now();
			});
		} else {
			usePlayStore.getState().set((draft) => {
				draft.pauseDuration += Date.now() - draft.pauseTimestamp;
				draft.pauseTimestamp = undefined;
			});
		}
	}, [isPaused]);

	const onRoomMessage = useCallback(
		(message, callback = null) => {
			if (!message) {
				if (callback) {
					callback();
				}
				return;
			}

			if (message) {
				if (message.joinTeam) {
					const teamConnectionId = message.joinTeam;

					console.log({ message });

					const team = usePlayStore.getState().teams.get(teamConnectionId);
					const player = usePlayStore.getState().players.get(message.connectionId);

					if (team && player && player.teamId !== teamConnectionId) {
						movePlayer(player.teamId, teamConnectionId, player.connectionId);
					}
				}

				if (message.voteQuizId && insertVoteRef.current) {
					insertVoteRef.current(message.voteQuizId, message.connectionId, message.voteQuizCount || 1);
				}

				if (message.rating) {
					updatePlayer(
						message.connectionId,
						(player) => {
							if (!isObject(player.ratings)) {
								player.ratings = {};
							}
							const { quizId, rating } = message.rating;
							if (
								!Object.prototype.hasOwnProperty.call(player.ratings, quizId) &&
								isNumber(rating) &&
								rating >= 1 &&
								rating <= 5
							) {
								apiPostQuizRating({
									sessionId,
									quizId,
									rating,
								});

								player.ratings[quizId] = rating;
							}
						},
						[]
					);
				}

				if (message.player) {
					updatePlayer(message.connectionId, (player) => {
						player.spectating = !!message.spectating;
						player.disconnected = false;

						if (safeNamesRef.current) {
							let name = getVerifiedAvatarSafeName(message.player.name);
							if (!isString(name)) {
								name = getRandomAvatarName();
							}
							message.player.name && void (player.name = name);
						} else {
							message.player.name && void (player.name = message.player.name);
						}

						if (isArray(message?.player?.avatar) || isString(message?.player?.avatar)) {
							player.avatar = message.player.avatar;
						}

						if (isString(message?.player?.country)) {
							player.country = message.player.country;
						}

						const previousCreated = player.created;
						if (isBoolean(message.player.created)) {
							player.created = message.player.created;
						}

						//Read player name in lobby
						if (
							statusWithProgress.name === PLAY_STATUS_LOBBY &&
							previousCreated === false &&
							player.created === true
						) {
							sfx.play("playerReady");

							const voiceClipName = player?.name;

							const newVoiceClips = cloneDeep(playedVoiceClips);
							newVoiceClips[player.connectionId] = voiceClipName;
							setPlayedVoiceClips(newVoiceClips);

							apiPatchAudioClip("Samantha", PLAYER_NAME_TTS_TRANSFORM(voiceClipName))
								.then(
									(result) =>
										void updatePlayer(message.connectionId, (player) => {
											player.voiceClip = result?.filename;
											player.voiceClipName = voiceClipName;
										})
								)
								.catch(console.error);
						}

						//Read player name when joining late
						if (
							statusWithProgress.name !== PLAY_STATUS_LOBBY &&
							statusWithProgress.name !== PLAY_STATUS_GAME_START &&
							previousCreated === false &&
							player.created === true &&
							!latePlayerRef.current
						) {
							if (!(player.connectionId in playedVoiceClips)) {
								const voiceClipName = PLAYER_NAME_TTS_TRANSFORM(player?.name);

								const newVoiceClips = cloneDeep(playedVoiceClips);
								newVoiceClips[player.connectionId] = voiceClipName;
								setPlayedVoiceClips(newVoiceClips);

								setLatePlayer(cloneDeep(player));

								apiPatchAudioClip("Samantha", voiceClipName + " joined")
									.then((result) => {
										const filename = result?.filename;

										if (filename) {
											voicePlayer.load(`${CDN_BASE_URL}/${filename}`, (error, sound) => {
												if (!error && mountedRef.current && !mutePlayerNamesRef.current) {
													voicePlayer.play(sound);
												}
											});
										}
									})
									.catch(console.error);
							}
						}
					});

					if (callback && !message.answer) {
						callback({}, message.connectionId);
					}
				}

				if (message.answer) {
					const { connectionId, serverTimestamp } = message;

					const {
						slideIndex: messageSlideIndex,
						answer,
						progress: guestProgress,
						unsubmitted,
						timestamp,
					} = message.answer;
					const {
						paused: isPaused,
						quiz,
						slideIndex,
						slideAnswerTime,
						pauseDuration,
						slideTimestamp,
					} = usePlayStore.getState();
					const { sendRoomMessage } = useWebSocketStore.getState();

					const slideIsWaitingForAnswers =
						statusWithProgress.name === PLAY_STATUS_WAIT_FOR_ANSWER && slideTimestamp !== undefined;

					if (
						(slideIsWaitingForAnswers || unsubmitted) &&
						isString(connectionId) &&
						isInteger(messageSlideIndex) &&
						messageSlideIndex === slideIndex &&
						!isPaused
					) {
						const connectionTimestamp = useWebSocketStore.getState().getConnectionTimestamp();
						const latency =
							isFinite(connectionTimestamp) && isFinite(serverTimestamp)
								? Math.max(connectionTimestamp - serverTimestamp, 0)
								: 0;
						const normalizedLatency = latency / slideAnswerTime || 0;
						const hostProgress = useStatusWithProgressStore.getState().progress - normalizedLatency;

						// trust guest progress if it doesn't deviate too much
						const progress = unsubmitted
							? 1
							: Math.abs(guestProgress - hostProgress) < 0.05
							? guestProgress
							: hostProgress;

						const points = (doublePoints ? 2 : 1) * getPointsFromAnswerProgress(progress);

						if (quiz && quiz.slides && slideIndex >= 0 && slideIndex < quiz.slides.length) {
							let shouldUpdatePlayer = false;
							let calculatedPoints = 0;
							const slideType = quiz.slides[slideIndex].type;
							const slideAnswers = quiz.slides[slideIndex].answers;
							let correctness = 0;
							let selectedAnswer = -1;

							if (slideType === SLIDE_TYPE_CLASSIC) {
								if (isInteger(answer) && answer >= 0 && answer < slideAnswers.length) {
									selectedAnswer = answer;

									if (slideAnswers[answer].isCorrect) {
										correctness = 1.0;
										calculatedPoints = points;
									}
									shouldUpdatePlayer = true;

									(callback ?? sendRoomMessage)(
										{
											response: {
												slideType,
												slideIndex,
												answer,
												progress,
											},
										},
										message.connectionId
									);
								}
							} else if (slideType === SLIDE_TYPE_CHECK_BOXES) {
								if (isArray(answer) && answer.every(isBoolean)) {
									const totalCorrect = slideAnswers.filter((ans) => ans.isCorrect).length;

									for (let i = 0, nrOfCorrect = 0; i < slideAnswers.length; i++) {
										if (slideAnswers[i].isCorrect) {
											if (answer[i] === true) {
												nrOfCorrect++;
												correctness = nrOfCorrect / totalCorrect;
											}
										} else {
											if (answer[i] === true) {
												correctness = 0;
												break;
											}
										}
									}

									calculatedPoints = Math.round(points * correctness);

									selectedAnswer = [...answer];

									shouldUpdatePlayer = true;

									(callback ?? sendRoomMessage)(
										{
											response: {
												slideType,
												slideIndex,
												answer,
												progress,
											},
										},
										message.connectionId
									);
								}
							} else if (slideType === SLIDE_TYPE_TYPE_ANSWER) {
								const result = typeAnswerTest(
									answer,
									slideAnswers.map(({ text }) => text)
								);

								let isCorrect = result === "correct";
								let isClose = result === "close";

								if (isCorrect) {
									correctness = 1.0;
									calculatedPoints = points;
									shouldUpdatePlayer = true;
								} else {
									updateTypeAnswerState((draft) => {
										if (!draft.wrongAnswers) {
											draft.wrongAnswers = [];
										}
										draft.wrongAnswers.push(answer);

										sendRoomMessage({
											wrongAnswers: draft.wrongAnswers,
										});
									});

									updatePlayer(connectionId, (player) => {
										player.wrongTypeAnswerSubmitted = slideIndex;
										player.inactive = false;
									});
								}

								(callback ?? sendRoomMessage)(
									{
										response: {
											slideType: SLIDE_TYPE_TYPE_ANSWER,
											slideIndex,
											isCorrect,
											isClose,
											progress,
										},
									},
									message.connectionId
								);
							} else if (slideType === SLIDE_TYPE_LOCATION) {
								if (mapTarget?.radius) {
									const distance = haversineDistance(answer, {
										lat: mapTarget?.lat,
										lng: mapTarget?.lng,
									});

									if (distance < mapTarget?.radius) {
										const rings = [1000, 800, 600, 400].map(
											(ring) => ring * (doublePoints ? 2 : 1)
										);
										const p = rings[Math.floor((distance / mapTarget?.radius) * rings.length)];

										correctness = 1.0;
										calculatedPoints = p;
									} else {
										calculatedPoints = 0;
										correctness = 0;
									}

									selectedAnswer = { ...answer, distance };
								} else if (correctAnswerFeature) {
									if (booleanPointInPolygon([answer.lng, answer.lat], correctAnswerFeature)) {
										correctness = 1.0;
										calculatedPoints = points;
									} else {
										correctness = 0;
										calculatedPoints = 0;
									}
									selectedAnswer = { ...answer };
								}

								shouldUpdatePlayer = true;

								(callback ?? sendRoomMessage)(
									{
										isPaused,
										response: {
											slideType,
											slideIndex,
											answer,
											progress,
										},
									},
									message.connectionId
								);
							} else if (slideType === SLIDE_TYPE_RANGE) {
								const [correctAnswer] = slideAnswers[0].text.split(" ");
								const submittedAnswerIndex = rangeGridRef.current.indexOf(answer);
								const correctAnswerIndex = rangeGridRef.current.indexOf(correctAnswer);

								const exactly = slideAnswers[2]?.text === "exactly";
								const error = Math.abs(submittedAnswerIndex - correctAnswerIndex);

								if (exactly) {
									if (error === 0) {
										correctness = 1;
									} else {
										correctness = 0;
									}
								} else {
									const errorThreshold = RANGE_SLIDE_ERROR_SCORE[
										rangeGridRef.current.length >= RANGE_SLIDE_ERROR_SCORE_BREAKPOINT ? 0 : 1
									].findIndex((threshold) => error <= threshold);

									if (errorThreshold >= 0) {
										correctness = 1 - 0.2 * errorThreshold;
									} else {
										correctness = 0;
									}
								}

								calculatedPoints = (doublePoints ? 2000 : 1000) * correctness;
								selectedAnswer = answer;

								shouldUpdatePlayer = true;

								(callback ?? sendRoomMessage)(
									{
										response: {
											slideType,
											slideIndex,
											answer,
											progress,
										},
									},
									message.connectionId // Send room message to user!
								);
							} else if (slideType === SLIDE_TYPE_REORDER) {
								if (
									isArray(answer) &&
									answer.length === slideAnswers.length &&
									answer.every(isInteger)
								) {
									let numCorrect = 0;
									let numTotal = 0;
									for (let i = 0; i < slideAnswers.length - 1; i++) {
										for (let j = i + 1; j < slideAnswers.length; j++) {
											if (answer[i] > answer[j] === slideAnswers[i].pos > slideAnswers[j].pos) {
												// is this pair correctly ordered?
												numCorrect = numCorrect + 1;
											}
											numTotal = numTotal + 1;
										}
									}
									correctness = numCorrect / numTotal;
									if (correctness === 1) {
										calculatedPoints = points;
									} else {
										calculatedPoints = 0;
									}

									shouldUpdatePlayer = true;

									selectedAnswer = [...answer];

									(callback ?? sendRoomMessage)(
										{
											response: {
												slideType,
												slideIndex,
												answer,
												progress,
											},
										},
										message.connectionId
									);
								}
							} else if (slideType === SLIDE_TYPE_PINPOINT) {
								if (pinpointPaths?.some((path) => inside(answer, path))) {
									correctness = 1.0;
									calculatedPoints = points;
								} else {
									correctness = 0;
									calculatedPoints = 0;
								}
								selectedAnswer = answer;
								shouldUpdatePlayer = true;

								(callback ?? sendRoomMessage)(
									{
										response: {
											slideType,
											slideIndex,
											answer,
											progress,
										},
									},
									message.connectionId
								);
							}

							if (shouldUpdatePlayer) {
								updatePlayer(connectionId, (player) => {
									if (!(slideIndex in player.history)) {
										const currentPoints = player.points;
										const newlyEarnedPoints = Math.round(calculatedPoints);
										player.spectating = !!message.spectating;
										player.disconnected = false;
										player.previousPoints = currentPoints;
										player.points = currentPoints + newlyEarnedPoints;
										player.selectedAnswer = selectedAnswer;
										player.correctness = correctness;
										player.answerProgress = progress;
										player.history[slideIndex] = {
											selectedAnswer,
											correctness,
											progress,
											points: newlyEarnedPoints,
											time: Date.now() - slideTimestamp - pauseDuration,
										};
									}
								});

								const teamId = usePlayStore.getState().players.get(connectionId)?.teamId;
								if (teamId) {
									updateTeams((teams) => {
										const team = teams.get(teamId);
										if (team) {
											const historyEntry = team.history[slideIndex] ?? { points: [] };
											historyEntry.points.push(calculatedPoints);
											team.history[slideIndex] = historyEntry;
										}
									});
								}
							}
						}
					}
				}
			}
		},
		[
			movePlayer,
			updatePlayer,
			sessionId,
			statusWithProgress.name,
			playedVoiceClips,
			mountedRef,
			doublePoints,
			updateTypeAnswerState,
			mapTarget?.radius,
			mapTarget?.lat,
			mapTarget?.lng,
			correctAnswerFeature,
			pinpointPaths,
			updateTeams,
		]
	);
	const onRoomMessageRef = useRef(onRoomMessage);
	useEffect(() => void (onRoomMessageRef.current = onRoomMessage), [onRoomMessage]);

	const onRoomClients = useCallback(
		(clients) => {
			updatePlayers((players) => {
				players.forEach((player, connectionId) => {
					const client = Object.entries(clients).find(
						(client) => client.length === 2 && client[0] === connectionId
					);
					if (connectionId === PLAYING_HOST_CONNECTION_ID) {
						player.disconnected = false;
					} else if (client && client.length === 2 && isFinite(client[1])) {
						const timeout = client[1];
						player.disconnected = timeout && timeout > PLAYER_DISCONNECT_TIMEOUT;
					} else {
						player.disconnected = true;
					}
				});
			});
		},
		[updatePlayers]
	);
	const onRoomClientsRef = useRef(onRoomClients);
	useEffect(() => void (onRoomClientsRef.current = onRoomClients), [onRoomClients]);

	useEffect(() => {
		const { addEventListener, removeEventListener, connect, connected } = useWebSocketStore.getState();

		const onRoomJoin = (message) => {
			if (onRoomJoinRef.current) {
				onRoomJoinRef.current(message);
			}
		};
		addEventListener(ROOM_JOIN, onRoomJoin);

		const onRoomMessage = (message) => {
			if (onRoomMessageRef.current) {
				onRoomMessageRef.current(message);
			}
		};
		addEventListener(ROOM_MESSAGE, onRoomMessage);

		const onRoomClients = (clients) => {
			if (onRoomClientsRef.current) {
				onRoomClientsRef.current(clients);
			}
		};
		addEventListener(ROOM_CLIENTS, onRoomClients);

		if (!connected) {
			console.log("Connect", WEB_SOCKET_URL);
			connect(WEB_SOCKET_URL);
		}

		return () => {
			removeEventListener(ROOM_JOIN, onRoomJoin);
			removeEventListener(ROOM_MESSAGE, onRoomMessage);
			removeEventListener(ROOM_CLIENTS, onRoomClients);
		};
	}, []);

	useEffect(() => {
		if (connected) {
			const { createRoom } = useWebSocketStore.getState();
			createRoom();
		}
	}, [connected]);

	const speedUpTime = useRef(0);

	const [enoughActivePlayersHaveAnswered, setEnoughActivePlayersHaveAnswered] = useState(false);

	useEffect(() => {
		const connectedPlayers = getConnectedPlayers([...players.values()]);

		speedUpTime.current = 0.5;

		if (connectedPlayers.length === 0) {
			setEnoughActivePlayersHaveAnswered(false);
			return; // !!!
		}

		if (connectedPlayers.length === 1) {
			setEnoughActivePlayersHaveAnswered(connectedPlayers.every((player) => slideIndex in player.history));
			return; // !!!
		}

		const activePlayers = connectedPlayers.filter((player) => !player.inactive);
		const inActivePlayers = connectedPlayers.filter((player) => player.inactive);

		if (activePlayers.length === 0) {
			setEnoughActivePlayersHaveAnswered(false);
			return; // !!!
		}

		const allActivePlayersAnswered = activePlayers.every((player) => slideIndex in player.history);

		const activePlayersAnswered = activePlayers.filter((player) => slideIndex in player.history);

		const enoughPlayersHaveAnswered = activePlayersAnswered.length / activePlayers.length >= 0.9;

		if (
			(allActivePlayersAnswered &&
				inActivePlayers.length > 0 &&
				!inActivePlayers.every((player) => slideIndex in player.history)) ||
			(enoughPlayersHaveAnswered && !allActivePlayersAnswered)
		) {
			speedUpTime.current = 3;
		}

		setEnoughActivePlayersHaveAnswered(enoughPlayersHaveAnswered);
	}, [players, slideIndex]);

	const showEnoughActivePlayersHaveAnsweredButton = useMemo(() => {
		if (statusWithProgress.name === PLAY_STATUS_WAIT_FOR_ANSWER && quiz && quiz.slides) {
			const slideObject = toSlideObject(quiz.slides[slideIndex], slideIndex);
			if (slideObject) {
				const normalAnswerTime = getAnswerTime({
					slideType: slideObject.type,
					revealType: slideObject.revealType,
					mediaSource: slideObject.media?.source,
				});

				const answerTime = getAnswerTime({
					answerTime: slideObject.answerTime,
					slideType: slideObject.type,
					revealType: slideObject.revealType,
					mediaSource: slideObject.media?.source,
				});
				if (answerTime > normalAnswerTime) {
					const currentAnswerTime = statusWithProgress.progress * answerTime;
					if (currentAnswerTime > normalAnswerTime) {
						return true;
					}
				}
			}
		}

		return false;
	}, [quiz, slideIndex, statusWithProgress]);

	const [enoughActivePlayersHaveAnsweredButtonClicked, setEnoughActivePlayersHaveAnsweredButtonClicked] =
		useState(false);
	useEffect(() => setEnoughActivePlayersHaveAnsweredButtonClicked(false), [quiz, slideIndex]);
	useEffect(() => {
		if (enoughActivePlayersHaveAnsweredButtonClicked) {
			setEnoughActivePlayersHaveAnswered(true);
		}
	}, [enoughActivePlayersHaveAnsweredButtonClicked]);

	const [stateEnded, setStateEnded] = useState(false);

	useEffect(() => void setStateEnded(false), [statusWithProgress.name]);

	const [omitQuizIds, updateOmitQuizIds] = useImmer([]);
	const omitQuizIdsRef = useRef(omitQuizIds);
	useEffect(() => void (omitQuizIdsRef.current = omitQuizIds), [omitQuizIds]);

	const omitQuizIdAdd = useCallback(
		(id) => {
			if (id && isUUID(id)) {
				updateOmitQuizIds((draft) => {
					const index = draft.indexOf(id);
					if (index !== -1) {
						draft.splice(index, 1);
					}
					draft.push(id);

					if (draft.length > NUMBER_OF_OMIT_QUIZZES) {
						draft.splice(0, draft.length - NUMBER_OF_OMIT_QUIZZES);
					}
				});
			}
		},
		[updateOmitQuizIds]
	);
	const omitQuizIdAddRef = useRef(omitQuizIdAdd);
	useEffect(() => void (omitQuizIdAddRef.current = omitQuizIdAdd), [omitQuizIdAdd]);

	useEffect(
		() =>
			void quiz?.slides
				.filter((slide) => !isEmpty(slide))
				.map((slide) => {
					if (omitQuizIdAddRef.current) {
						omitQuizIdAddRef.current(slide?.quiz?.id);
					}
				}),
		[quiz, updateOmitQuizIds]
	);

	useEffect(() => {
		if (omitQuizIdAddRef.current) {
			omitQuizIdAddRef.current(quiz?.id);
		}
	}, [quiz, updateOmitQuizIds]);

	const onQuizVoteCancel = useCallback(() => {
		updateOmitQuizIds((draft) => void draft.splice(0));
		setNextQuizId(QUIZ_STOP_ID);
		setPlaylistSkip(true);
	}, [updateOmitQuizIds, setNextQuizId, setPlaylistSkip]);

	useEffect(() => {
		if (!stateEnded && statusWithProgress.name === PLAY_STATUS_VOTE_PREP) {
			setStateEnded(true);

			setMyVoteQuizId(null);
			updateVoteMap((draft) => {
				for (const quizId in draft) {
					delete draft[quizId];
				}
			});

			setNextQuizId(null);
			setQuiz({ ...quiz, name: "" });

			const args = { numQuizzes: 5 };

			if (quickMode) {
				args.minNumPlayableSlides = 4;
			} else {
				args.minNumSlides = 8;
				args.maxNumSlides = 12;
			}

			// Always omit voted for quizzes
			args.omitQuizIds = isArray(omitQuizIdsRef.current) ? omitQuizIdsRef.current : [];

			args.excludeYoutube = useSettingsStore.getState().excludeYoutube;

			apiGetRandomQuizzes(args)
				.then((quizzes) => {
					if (quizzes && quizzes.length > 0) {
						quizzes.map((quiz) => {
							if (omitQuizIdAddRef.current) {
								omitQuizIdAddRef.current(quiz.id);
							}
						});

						quizzes.push({ id: QUIZ_DEAL_ID, name: trans("Deal %d new quizzes", quizzes.length) });

						usePlayStore.getState().setRecommendedQuizzes(quizzes);
						usePlayStore.getState().setPlaylistSkip(true);

						updateVoteMap((draft) => {
							for (const quiz of quizzes) {
								draft[quiz.id] = [];
							}
						});
					} else {
						usePlayStore.getState().setRecommendedQuizzes([]);
						onQuizVoteCancel();
					}
				})
				.catch((err) => {
					console.error(err);
					onQuizVoteCancel();
				});
		}
	}, [
		onQuizVoteCancel,
		players,
		stateEnded,
		statusWithProgress.name,
		updatePlayer,
		quickMode,
		sessionId,
		quiz,
		setQuiz,
		setNextQuizId,
		updateOmitQuizIds,
		updateVoteMap,
		setVoteMode,
	]);

	useEffect(() => {
		if (!stateEnded && statusWithProgress.name === PLAY_STATUS_WAIT_FOR_ANSWER && enoughActivePlayersHaveAnswered) {
			setStateEnded(true);

			const newTime = quiz?.slides[slideIndex]?.revealType ? 3 : speedUpTime.current;

			if (quiz?.slides[slideIndex]?.revealType) {
				useStatusWithProgressStore
					.getState()
					.updateDuration(Math.min(statusWithProgress.elapsedTime + newTime, statusWithProgress.duration));
			} else {
				useStatusWithProgressStore
					.getState()
					.updateDuration(Math.min(statusWithProgress.elapsedTime + newTime, statusWithProgress.duration));
			}
		}
	}, [
		enoughActivePlayersHaveAnswered,
		quiz?.slides,
		slideIndex,
		stateEnded,
		statusWithProgress.duration,
		statusWithProgress.elapsedTime,
		statusWithProgress.name,
	]);

	useEffect(() => {
		if (!stateEnded && statusWithProgress.name === PLAY_STATUS_VOTE_SHOW && allPlayersHaveVoted) {
			setStateEnded(true);

			const singlePlayer = getConnectedPlayers([...usePlayStore.getState().players.values()]).length <= 1;

			const t = singlePlayer ? 1 : 4;

			useStatusWithProgressStore
				.getState()
				.updateDuration(Math.min(statusWithProgress.elapsedTime + t, statusWithProgress.duration));
		}
	}, [
		allPlayersHaveVoted,
		stateEnded,
		statusWithProgress.duration,
		statusWithProgress.elapsedTime,
		statusWithProgress.name,
	]);

	useEffect(() => void setCode(roomId), [roomId, setCode]);

	const isConnecting = useMemo(
		() => props.quizId !== QUIZ_AI_ID && !quickMode && (!quiz || !roomId),
		[props.quizId, quickMode, quiz, roomId]
	);

	const [slideMediaIsPlayingWithSound, setSlideMediaIsPlayingWithSound] = useState(false);
	const [funFactMediaIsPlayingWithSound, setFunFactMediaIsPlayingWithSound] = useState(false);

	useEffect(() => {
		if (slideMediaIsPlayingWithSound || funFactMediaIsPlayingWithSound) {
			fadeOutMusic();
		} else {
			fadeInMusic();
		}
	}, [fadeInMusic, fadeOutMusic, funFactMediaIsPlayingWithSound, slideMediaIsPlayingWithSound]);

	const onQuestionStart = useCallback(() => {
		if (!stateEnded && statusWithProgress.name === PLAY_STATUS_WAIT_FOR_MEDIA) {
			setStateEnded(true);
			usePlayStore.getState().setPlaylistSkip(true);
		}
	}, [stateEnded, statusWithProgress.name]);

	const onFunFactEnd = useCallback(() => {
		if (!stateEnded && statusWithProgress.name === PLAY_STATUS_SHOW_FUN_FACT) {
			setStateEnded(true);
			usePlayStore.getState().setPlaylistSkip(true);
		}
	}, [stateEnded, statusWithProgress.name]);

	useEffect(
		// Reset type answer state when slide changes
		() => {
			updateTypeAnswerState((draft) => {
				draft.value = "";
				draft.isClose = null;
				draft.isCorrect = null;
				draft.isNumeric = false;
				draft.wrongAnswers = [];
			});
		},
		[slideIndex, updateTypeAnswerState]
	);

	function sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	useEffect(() => {
		// Load random slides from nextQuiz
		if (!stateEnded && statusWithProgress.name === PLAY_STATUS_LOAD_QUIZ && nextQuizId && isUUID(nextQuizId)) {
			setStateEnded(true);

			const numEmptySlides = quiz.slides.reduce((n, slide) => (isEmpty(slide) ? n + 1 : n), 0);

			if (numEmptySlides > 0) {
				const numSlides = Math.min(useSettingsStore.getState().numQuestionsPerRound, numEmptySlides);
				const omitSlideIds = quiz.slides.filter((slide) => !isEmpty(slide)).map((slide) => slide.id);
				const excludeYoutube = useSettingsStore.getState().excludeYoutube;

				const quizId = nextQuizId;
				setNextQuizId(null);

				apiGetRandomSlides({ count: numSlides, quizId, omitSlideIds, excludeYoutube }).then((loadedSlides) => {
					const slides = cloneDeep(quiz.slides);

					let name = null;
					for (let i = 0; i < Math.min(loadedSlides.length, numSlides); i++) {
						// Randomize answers
						if ([SLIDE_TYPE_CLASSIC, SLIDE_TYPE_CHECK_BOXES].includes(loadedSlides[i].type)) {
							loadedSlides[i].answers.sort((a, b) => a.text.localeCompare(b.text));
						}

						if (!name && loadedSlides?.[i].quiz?.name) {
							name = loadedSlides[i].quiz.name;
						}

						// Find first empty slide slod and populate with loaded slides
						for (let j = 0; j < slides.length; j++) {
							if (isEmpty(slides[j])) {
								slides[j] = loadedSlides[i];
								break; // !!!
							}
						}
					}

					setQuiz({ ...quiz, slides, name: name || "" });
					setPlaylistSkip(true);
				});
			}
		}
	}, [nextQuizId, quiz, setNextQuizId, setPlaylistSkip, setQuiz, stateEnded, statusWithProgress.name]);

	const onStart = useCallback(
		async (nrOfSlides, categories) => {
			if (forceStartTimeout.current) {
				clearTimeout(forceStartTimeout.current);
				forceStartTimeout.current = null;
			}

			if (quickMode) {
				const numSlides =
					useSettingsStore.getState().numRoundsPerGame * useSettingsStore.getState().numQuestionsPerRound;

				switch (useSettingsStore.getState().quizSelectMode) {
					case "questions/random":
						apiGetRandomSlides({ count: numSlides }).then((slides) => {
							setQuiz({ slides });
						});
						break;
					case "quiz/vote":
						setQuiz({ slides: new Array(numSlides).fill({}) });
						break;
				}
			}

			usePlayStore.getState().updateStatus(PLAY_STATUS_GAME_START, 1);
			useStatusWithProgressStore.getState().updateStatus(PLAY_STATUS_GAME_START, 1);
		},
		[quickMode, setQuiz]
	);

	const onLocationContinue = useCallback(() => {
		usePlayStore.getState().updateStatus(PLAY_STATUS_PREP_LEADERBOARD, 0);
		useStatusWithProgressStore.getState().updateStatus(PLAY_STATUS_PREP_LEADERBOARD, 0);
	}, []);

	const [showLoadingQuestions, setShowLoadingQuestions] = useState(false);

	const onLoad = useCallback(() => void setSlideLoaded(true), [setSlideLoaded]);
	const onComplete = useCallback(() => void setSlideComplete(true), [setSlideComplete]);

	useEffect(() => {
		if (!stateEnded && statusWithProgress.name === PLAY_STATUS_LOAD_SLIDE && slideLoaded) {
			setStateEnded(true);
			// const elapsedTime = statusWithProgress.progress * statusWithProgress.duration;
			useStatusWithProgressStore.getState().updateDuration(statusWithProgress.elapsedTime);
		}
	}, [slideLoaded, stateEnded, statusWithProgress.elapsedTime, statusWithProgress.name]);

	const [localPlayerScore, setLocalPlayerScore] = useState({ points: 0, rank: undefined });

	const onSendScore = useCallback(() => {
		const players = playersRef.current ?? [];
		const connectedPlayers = getConnectedPlayers([...players.values()]);
		if (teamModeRef.current) {
			const teams = teamsRef.current ?? [];
			const teamsArray = [...teams.values()];
			teamsArray.sort((a, b) => b.points - a.points);

			for (const player of connectedPlayers) {
				const teamIndex = teamsArray.findIndex((team) => team.connectionId === player.teamId);
				if (teamIndex >= 0) {
					const { points } = teamsArray[teamIndex];
					const rank = teamIndex + 1;

					if (player.connectionId === PLAYING_HOST_CONNECTION_ID) {
						setLocalPlayerScore({ points, rank });
					}
					useWebSocketStore.getState().sendRoomMessage(
						{
							points,
							rank,
						},
						player.connectionId
					);
				}
			}
		} else {
			connectedPlayers.sort((a, b) => b.points - a.points);
			for (let i = 0; i < connectedPlayers.length; i++) {
				const player = connectedPlayers[i];
				if (player.connectionId === PLAYING_HOST_CONNECTION_ID) {
					setLocalPlayerScore({ points: player.points, rank: i + 1 });
				}
				useWebSocketStore.getState().sendRoomMessage(
					{
						rank: i + 1,
						points: player.points,
					},
					player.connectionId
				);
			}
		}
	}, []);

	const desktopSlide = useMemo(() => {
		if (quiz && isArray(quiz.slides) && slideIndex >= 0 && slideIndex < quiz.slides.length) {
			const slide = cloneDeep(quiz.slides[slideIndex]);
			slide.media = getMediaBySlide(slide);
			slide.funFactMedia = getFunFactMediaBySlide(slide);
			return slide;
		} else {
			return null;
		}
	}, [quiz, slideIndex]);

	const desktopSlideRef = useRef(desktopSlide);
	useEffect(() => void (desktopSlideRef.current = desktopSlide), [desktopSlide]);

	useEffect(() => {
		noSleep.initialize();
		const enable = () => void noSleep.enable();
		document.addEventListener("click", enable);
		return () => {
			document.removeEventListener("click", enable);
			noSleep.destroy();
		};
	}, []);

	const [layoutBackground, updateLayoutBackground] = useImmer({
		color: PETROL_DARK,
		duration: 0,
	});

	useEffect(() => {
		let backgroundColor = null;

		if (slideIndex >= 0) {
			backgroundColor = BACKGROUND_COLORS[Math.max(slideIndex, 0) % BACKGROUND_COLORS.length];
		}

		if (aiMode) {
			backgroundColor = PETROL_DARKEST;
		} else if (statusWithProgress.name === PLAY_STATUS_LOBBY) {
			backgroundColor = PETROL_DARK;
		} else if (
			desktopSlide?.type === SLIDE_TYPE_TYPE_ANSWER &&
			[
				PLAY_STATUS_WAIT_FOR_ANSWER,
				PLAY_STATUS_SHOW_CORRECT_ANSWER,
				PLAY_STATUS_SHOW_AVATAR_COLORS,
				PLAY_STATUS_ALL_ANSWERS_RECEIVED,
				PLAY_STATUS_SHOW_AVATAR_CORRECTNESS,
			].includes(statusWithProgress.name)
		) {
			if (typeAnswerState.isCorrect) {
				backgroundColor = GREEN_LIGHT;
			} else if (typeAnswerState.isClose) {
				backgroundColor = YELLOW_DARK;
			} else if (typeAnswerState.isCorrect === false) {
				backgroundColor = PINK;
			}
		}

		if (!backgroundColor) {
			backgroundColor = PETROL_DARK;
		}

		const backgroundTransitionDuration = 0.5;

		updateLayoutBackground((draft) => {
			draft.color = backgroundColor;
			draft.duration = backgroundTransitionDuration;
		});
	}, [
		desktopSlide?.type,
		aiMode,
		slideIndex,
		statusWithProgress?.name,
		typeAnswerState.isClose,
		typeAnswerState.isCorrect,
		updateLayoutBackground,
	]);

	const onTeamEdit = useCallback((ev) => void setTeamAvatarCustomizeActive(ev.currentTarget.dataset.teamId), []);

	const onLocalJoin = useCallback(
		(autoStart = false) => {
			setAvatarCustomizeActive(true);
			setAutoStartAfterAvatarCustomize(autoStart);

			if (players.has(PLAYING_HOST_CONNECTION_ID)) {
				updatePlayer(PLAYING_HOST_CONNECTION_ID, (player) => void (player.created = false));
			}

			if (forceStartTimeout.current) {
				clearTimeout(forceStartTimeout.current);
				forceStartTimeout.current = null;
			}
		},
		[players, updatePlayer]
	);
	const onLocalJoinRef = useRef(onLocalJoin);
	useEffect(() => void (onLocalJoinRef.current = onLocalJoin), [onLocalJoin]);

	const onLocalJoinConfirm = useCallback(() => {
		setLocalJoinConfirm(false);
		setAvatarCustomizeActive(true);

		if (players.has(PLAYING_HOST_CONNECTION_ID)) {
			updatePlayer(PLAYING_HOST_CONNECTION_ID, (player) => void (player.created = false));
		}

		if (forceStartTimeout.current) {
			clearTimeout(forceStartTimeout.current);
			forceStartTimeout.current = null;
		}
	}, [players, updatePlayer]);
	const onLocalJoinConfirmRef = useRef(onLocalJoinConfirm);
	useEffect(() => void (onLocalJoinConfirmRef.current = onLocalJoinConfirm), [onLocalJoinConfirm]);

	const onLocalAnswer = useCallback(
		(slideIndex, answer, callback = null, unsubmitted = null) => {
			const { haveLocalPlayer } = usePlayStore.getState();
			if (!haveLocalPlayer) {
				setLocalJoinConfirm(true);
				return;
			}

			if (desktopSlide && desktopSlide.answers && desktopSlide.type === SLIDE_TYPE_TYPE_ANSWER) {
				const result = typeAnswerTest(
					answer,
					desktopSlide.answers.map(({ text }) => text)
				);
				updateTypeAnswerState((draft) => {
					draft.isClose = result === "close";
					draft.isCorrect = result === "correct";
				});
			}

			if (!haveLocalPlayer) {
				if (onRoomJoinRef.current) {
					onRoomJoinRef.current(
						{
							roomId,
							connectionId: PLAYING_HOST_CONNECTION_ID,
							valid: false,
						},
						(message, connectionId) => {
							if (onRoomMessage) {
								let name = safeNamesRef.current ? getInitialAvatarSafeName() : getInitialAvatarName();
								if (!isString(name)) {
									name = getRandomAvatarName();
								}

								const player = {
									name,
									avatar: getInitialAvatar(),
								};

								onRoomMessage(
									{
										connectionId,
										player,
									},
									(message, connectionId) => {
										const connectionTimestamp = useWebSocketStore
											.getState()
											.getConnectionTimestamp();

										if (onRoomMessage) {
											onRoomMessage(
												{
													answer: {
														slideIndex,
														answer,
														progress: useStatusWithProgressStore.getState().progress,
														unsubmitted,
														timestamp: Date.now(),
													},
													serverTimestamp: connectionTimestamp,
													connectionId,
												},
												(message, connectionId) => {
													if (callback) {
														callback(message, connectionId);
													}
												}
											);
										}
									}
								);
							}
						}
					);
				}
			} else {
				const connectionId = PLAYING_HOST_CONNECTION_ID;
				const connectionTimestamp = useWebSocketStore.getState().getConnectionTimestamp();

				if (onRoomMessage) {
					onRoomMessage(
						{
							answer: {
								slideIndex,
								answer,
								progress: useStatusWithProgressStore.getState().progress,
								unsubmitted,
								timestamp: Date.now(),
							},
							serverTimestamp: connectionTimestamp,
							connectionId,
						},
						(message, connectionId) => {
							if (callback) {
								callback(message, connectionId);
							}
						}
					);
				}
			}
		},
		[desktopSlide, updateTypeAnswerState, roomId, onRoomMessage]
	);

	const sendPlayerPointsAndPos = useCallback(
		(playerOrTeam, rank) => {
			const { sendRoomMessage } = useWebSocketStore.getState();

			const { points } = playerOrTeam;
			const team = teams.get(playerOrTeam.connectionId);
			if (team) {
				// Send to all team members
				const connectedMembersArray = Array.from(players.values()).filter(
					(player) => isPlayerConnected(player) && player.teamId === team.connectionId
				);
				for (const player of connectedMembersArray) {
					sendRoomMessage({ rank, points }, player.connectionId);
				}
			} else {
				// Send to one player
				sendRoomMessage({ rank, points }, playerOrTeam.connectionId);
			}
		},
		[players, teams]
	);

	const localPlayerCanRate = useMemo(() => {
		if (!quickMode && user && user.id === quiz?.owner?.id) {
			return haveLocalPlayer && (players.size > 1 || quiz?.generated);
		} else {
			return haveLocalPlayer;
		}
	}, [haveLocalPlayer, players.size, quickMode, quiz?.owner?.id, user, quiz?.generated]);

	const setRating = useMemo(
		() =>
			localPlayerCanRate
				? (rating, quizId) => {
						apiPostQuizRating({
							sessionId,
							quizId,
							rating,
						});

						updatePlayer(PLAYING_HOST_CONNECTION_ID, (player) => {
							player.ratings = isObject(player.ratings)
								? { ...player.ratings, [quizId]: rating }
								: { [quizId]: rating };
						});
				  }
				: undefined,

		[localPlayerCanRate, sessionId, updatePlayer]
	);

	const onCancelAvatarCutomize = useCallback(() => {
		setAvatarCustomizeActive(false);
		setAutoStartAfterAvatarCustomize(false);

		if (players.has(PLAYING_HOST_CONNECTION_ID)) {
			updatePlayer(PLAYING_HOST_CONNECTION_ID, (player) => void (player.created = true));
		}

		if (forceStartTimeout.current) {
			clearTimeout(forceStartTimeout.current);
			forceStartTimeout.current = null;
		}
	}, [players, updatePlayer]);

	const onDoneAvatarCustomize = useCallback(() => {
		setAvatarCustomizeActive(false);

		if (forceStartTimeout.current) {
			clearTimeout(forceStartTimeout.current);
			forceStartTimeout.current = null;
		}

		if (autoStartAfterAvatarCustomize) {
			forceStartTimeout.current = setTimeout(() => {
				forceStartTimeout.current = null;
				if (lobbyRef.current) {
					lobbyRef.current.startIfOnlyLocalPlayer();
				}
			}, 1000);
		}

		setAutoStartAfterAvatarCustomize(false);
	}, [autoStartAfterAvatarCustomize]);

	const onError = useCallback(() => {
		addSkippedSlide();
		skipToNextSlide();
	}, [skipToNextSlide, addSkippedSlide]);

	useEffect(() => {
		if (statusWithProgress.name === PLAY_STATUS_FLUSH) {
			sfx.stop();
		}
	}, [statusWithProgress.name]);

	const showPoints = useMemo(
		() => [PLAY_STATUS_SHOW_LEADERBOARD].includes(statusWithProgress.name),
		[statusWithProgress.name]
	);

	useEffect(() => {
		if (isPaused) {
			googleAnalyticsEvent("quiz_pause", "host");
		}
	}, [isPaused]);

	const [canRate, setCanRate] = useState(false);

	const rateQuizzes = useRateQuizzes(quiz?.slides);

	const onConfirmConfirmDialog = useCallback(() => void onLocalJoinConfirm(), [onLocalJoinConfirm]);
	const onCancelConfirmDialog = useCallback(() => void setLocalJoinConfirm(false), []);

	const onClickContinueButton = useCallback(() => void setPlaylistSkip(true), [setPlaylistSkip]);
	const onClickEnoughActivePlayersHaveAnswered = useCallback(
		() => void setEnoughActivePlayersHaveAnsweredButtonClicked(true),
		[]
	);

	const onWinnerRevealed = useCallback(() => {
		(async () => {
			await sleep(1000);
			if (mountedRef.current && statusWithProgress.name === PLAY_STATUS_SHOW_WINNER) {
				setPlaylistSkip(true);
			}
		})();
	}, [mountedRef, setPlaylistSkip, statusWithProgress.name]);

	const voteQueueRef = useRef(new Map());
	const voteQueueTimeoutRef = useRef(null);
	const voteQueuePreviousRef = useRef(Date.now());
	const onVote = useCallback(
		(quizId) => {
			voteQueueRef.current.set(quizId, (voteQueueRef.current.get(quizId) || 0) + 1);
			if (!voteQueueTimeoutRef.current && mountedRef.current) {
				const timeout = Math.min(
					Math.max(VOTE_THROTTLE_TIMEOUT - (Date.now() - voteQueuePreviousRef.current), 0),
					VOTE_THROTTLE_TIMEOUT
				);
				voteQueuePreviousRef.current = Date.now();

				voteQueueTimeoutRef.current = setTimeout(() => {
					voteQueueTimeoutRef.current = null;
					for (const [quizId, votes] of voteQueueRef.current.entries()) {
						insertVoteRef.current(quizId, PLAYING_HOST_CONNECTION_ID, votes);
					}
					voteQueueRef.current.clear();
				}, timeout);
			}
			setMyVoteQuizId(quizId);
		},
		[mountedRef]
	);

	const onResetTimer = useCallback(() => {
		voteQueueRef.current.clear();
		if (voteQueueTimeoutRef.current) {
			clearTimeout(voteQueueTimeoutRef.current);
			voteQueueTimeoutRef.current = null;
		}
		setResetTimerActive(true);
		resetTimer(voteMode === VOTE_MODE_CRAZY_CLICK_MODE, true);
	}, [resetTimer, voteMode]);

	const onPlaylistSkip = useCallback(() => void setPlaylistSkip(true), [setPlaylistSkip]);

	const onSetSlideTypeAnswer = useCallback(
		(value) =>
			void updateTypeAnswerState((draft) => {
				draft.value = value;
				draft.isClose = null;
				draft.isCorrect = null;
			}),
		[updateTypeAnswerState]
	);

	const [showRateFragment, setShowRateFragment] = useState(false);
	useEffect(() => {
		if (![PLAY_STATUS_SHOW_RATE, PLAY_STATUS_RATE_DONE].includes(statusWithProgress.name)) {
			setShowRateFragment(false);
		} else if (statusWithProgress.name === PLAY_STATUS_SHOW_RATE) {
			setShowRateFragment(true);
		}
	}, [statusWithProgress.name]);

	useEffect(() => {
		if (statusWithProgress.name === PLAY_STATUS_ALL_ANSWERS_RECEIVED) {
			// Calculate team points when all answers have been received
			updateTeams((teams) => {
				for (const [id, team] of teams) {
					team.previousPoints = team.points;
					let totalPoints = 0;
					for (const entry of Object.values(team.history)) {
						const slidePoints = Math.round(
							entry.points.reduce((sum, value) => sum + value, 0) / entry.points.length
						);
						totalPoints += slidePoints;
					}
					team.points = totalPoints;
				}
			});
		}
	}, [statusWithProgress.name, updateTeams]);

	const enableRemoveTeam = useMemo(() => teams && teams.size > 2, [teams]);

	const myTeamId = useMemo(() => players.get(PLAYING_HOST_CONNECTION_ID)?.teamId, [players]);
	const myTeam = useMemo(() => myTeamId && teams.get(myTeamId), [myTeamId, teams]);

	const onRemoveTeam = useCallback(
		(teamId) => {
			updatePlayers((players) => {
				for (const [connectionId, player] of players) {
					if (player.teamId === teamId) {
						delete player.teamId;
					}
				}
			});
			updateTeams((teams) => void teams.delete(teamId));
		},
		[updatePlayers, updateTeams]
	);

	return mounted ? (
		<ScaleWrapper className="print:hidden flex flex-col justify-center flex-1 select-none">
			<LayoutBackground color={layoutBackground.color} duration={layoutBackground.duration} />
			{["local", "dev"].includes(process.env.NEXT_PUBLIC_INSTANCE_NAME) && (
				<StateView name={statusWithProgress.name} progress={statusWithProgress.progress} n={5} />
			)}
			{leaveURL && (
				<LeaveDialog
					stayURL={`/play/${props.quizId}/`}
					leaveURL={leaveURL}
					setLeaveURL={setLeaveURL}
					stopHosting={stopHosting}
				/>
			)}
			<Playlist quickMode={quickMode} />
			<BackgroundAnimation
				quiz={quiz}
				slideIndex={slideIndex}
				statusName={statusName}
				aiMode={aiMode}
				backgroundAnimationEnabled={backgroundAnimationEnabled}
				reducedMotion={reducedMotion}
			/>
			{haveLocalPlayer && showPoints && (
				<div className="md:top-10 top-8 absolute left-0">
					<PlayerScore
						name={players.get(PLAYING_HOST_CONNECTION_ID)?.name}
						points={localPlayerScore?.points}
						rank={localPlayerScore?.rank}
						teamName={myTeam?.name}
					/>
				</div>
			)}

			{teamAvatarCustomizeActive && (
				<AvatarCustomizeModalTeam
					roomId={roomId}
					showDone={true}
					onDone={(newProperties) => {
						updateTeams((teams) => {
							const team = teams.get(teamAvatarCustomizeActive);
							if (team) {
								if (!isEqual(team.avatarLayers, newProperties.avatar)) {
									team.avatarLayers = newProperties.avatar;
									delete team.avatar; // will force re-upload
								}
								team.name = newProperties.name;
							}
						});
						setTeamAvatarCustomizeActive(null);
					}}
					useSafeNames={safeNames}
					team={teams.get(teamAvatarCustomizeActive)}
					enableRemoveTeam={enableRemoveTeam}
					onRemoveTeam={onRemoveTeam}
					avatar={teams.get(teamAvatarCustomizeActive).avatarLayers}
					isHost={true}
					showCode={showCode}
					joinOpen={joinOpen}
				/>
			)}

			{avatarCustomizeActive && (
				<AvatarCustomizeModal
					roomId={roomId}
					showDone={true}
					onCancel={onCancelAvatarCutomize}
					onDone={onDoneAvatarCustomize}
					useSafeNames={safeNames}
					onRoomMessage={onRoomMessage}
					onRoomJoin={onRoomJoin}
					playerState={playerState}
					isHost={true}
					showCode={showCode}
					joinOpen={joinOpen}
				/>
			)}
			{localJoinConfirm && (
				<ConfirmDialog
					className="w-96"
					text={trans("Would you like to play on this device?")}
					confirmText={trans("Join on this device")}
					confirmColor={GREEN_LIGHT}
					extraText={trans("Cancel")}
					extraColor={PINK_LIGHT}
					layout="vertical"
					onConfirm={onConfirmConfirmDialog}
					onExtra={onCancelConfirmDialog}
				/>
			)}
			{aiError && (
				<ConfirmDialog
					className="sm:w-96 w-56"
					text={
						!aiBadTopic
							? trans("Unable to generate a Quiz with Artificial Intelligence.")
							: trans("The topic might be unclear to the AI or inappropriate, please try another topic.")
					}
					confirmText={!aiBadTopic ? trans("Try again") : trans("Ok")}
					confirmColor={GREEN_LIGHT}
					extraText={trans("Cancel")}
					extraColor={PINK_LIGHT}
					layout="vertical"
					onConfirm={!aiBadTopic ? onConfirmErrorGenerating : onCancelErrorGenerating}
					onExtra={!aiBadTopic ? onCancelErrorGenerating : null}
				/>
			)}
			{(() => {
				if (isError) {
					return (
						<div className="relative flex flex-col items-center justify-center w-full h-full text-center">
							<Header>{trans("Quiz could not be loaded. Please try again later.")}</Header>
						</div>
					);
				}

				if (!isAIGeneratedQuiz && !aiGenerating && !aiMode && isLoading) {
					return (
						<div className="absolute inset-0">
							<Loading />
						</div>
					);
				}

				if (started) {
					return (
						<>
							<HideMouse />
							{isPaused && <GamePaused />}
							<div className="sm:pt-0 pt-9 md:overflow-hidden absolute top-0 left-0 w-full h-full overflow-auto">
								<div className="relative top-0 left-0 w-full h-full">
									<div className="absolute top-0 left-0 w-full h-full">
										{showContinue && (
											<div className="left-1/2 transform -translate-x-1/2 top-2 md:top-16 sm:top-12 absolute z-10 w-full sm:w-[var(--width)] pr-2 sm:pr-[calc(var(--scale)*2rem-0.5rem)]">
												<div className="relative flex flex-col items-end justify-start w-full">
													<ContinueButton onClick={onClickContinueButton} />
												</div>
											</div>
										)}
										{!showContinue &&
											showEnoughActivePlayersHaveAnsweredButton &&
											!enoughActivePlayersHaveAnswered &&
											!enoughActivePlayersHaveAnsweredButtonClicked && (
												<div className="left-1/2 transform -translate-x-1/2 top-2 md:top-16 sm:top-12 absolute z-10 w-full sm:w-[var(--width)] pr-2 sm:pr-[calc(var(--scale)*2rem-0.5rem)]">
													<div className="relative flex flex-col items-end justify-start w-full">
														<ContinueButton
															onClick={onClickEnoughActivePlayersHaveAnswered}
														/>
													</div>
												</div>
											)}
										<Avatar
											className={tailwindCascade(
												"top-24 right-16 absolute z-1 scale-120 transition-all opacity-100 duration-500 ease-[cubic-bezier(0,2.5,0.8,1)]",
												{
													"scale-0": !latePlayer || teamMode,
													"opacity-0": !latePlayer || teamMode,
												}
											)}
											playerAvatar={latePlayer?.avatar}
											playerName={latePlayer?.name}
											playerPoints={latePlayer?.previousPoints}
											playerConnectionId={latePlayer?.connectionId}
											showShuffle={false}
											showName={true}
											showPoints={false}
											checkmark={false}
											border={true}
											showJoined={true}
										/>
										<div
											className={tailwindCascade(
												"top-16 right-12 z-1 absolute text-xl font-bold text-white transition-opacity opacity-100 duration-500 ease-in-out",
												{
													"opacity-0": !latePlayer && teamMode,
												}
											)}
										>
											{latePlayer
												? `${latePlayer.name} joined ${teams.get(latePlayer.teamId)?.name}`
												: ""}
										</div>
										<div className="absolute inset-0">
											<div className="flex flex-col w-full h-full">
												<div className="relative flex-grow w-full overflow-x-hidden">
													{[PLAY_STATUS_SHOW_GET_READY, PLAY_STATUS_HIDE_GET_READY].includes(
														statusWithProgress.name
													) && (
														<GetReady
															onLoad={onLoad}
															onComplete={onComplete}
															visible={
																PLAY_STATUS_SHOW_GET_READY === statusWithProgress.name
															}
															isHost={true}
														/>
													)}
													{statusWithProgress.name === PLAY_STATUS_SHOW_LEADERBOARD && (
														<Leaderboard
															onLoad={onLoad}
															onComplete={onComplete}
															leaderboard={leaderboard}
															onSendScore={onSendScore}
															hidePlayerCountry={teamMode ? true : hidePlayerCountry}
														/>
													)}
													{[
														PLAY_STATUS_SHOW_WINNER,
														PLAY_STATUS_SHOW_RATE,
														PLAY_STATUS_RATE_DONE,
													].includes(statusWithProgress.name) && (
														<>
															<RateModal
																canRate={canRate}
																quizzes={rateQuizzes}
																ratings={
																	localPlayerCanRate
																		? players.get(PLAYING_HOST_CONNECTION_ID)
																				.ratings
																		: undefined
																}
																setRating={setRating}
																showRateFragment={showRateFragment}
															/>
															<GameEnd
																onLoad={onLoad}
																onComplete={onPlaylistSkip}
																isHost={true}
																leaderboard={leaderboard}
																players={players}
																quickMode={quickMode}
																sendPlayerPointsAndPos={sendPlayerPointsAndPos}
																onWinnerRevealed={onWinnerRevealed}
																statusWithProgress={statusWithProgress}
																setCanRate={setCanRate}
																routeChangeStart={onRouteChangeStart}
																reducedMotion={reducedMotion}
																hidePlayerCountry={teamMode ? true : hidePlayerCountry}
																quiz={quiz}
																report={report}
															/>
														</>
													)}
													{PLAY_STATUSES_VOTE.includes(statusWithProgress.name) && (
														<QuizVote
															isHost={true}
															isPaused={isPaused}
															nextQuizId={nextQuizId}
															onComplete={onPlaylistSkip}
															onVote={onVote}
															quizzes={recommendedQuizzes}
															sessionId={sessionId}
															setNextQuizId={setNextQuizId}
															statusWithProgress={statusWithProgress}
															voteQuizId={
																voteMode === VOTE_MODE_CRAZY_CLICK_MODE
																	? undefined
																	: myVoteQuizId
															}
															slideIndex={slideIndex}
															numQuestionsPerRound={numQuestionsPerRound}
															numRoundsPerGame={numRoundsPerGame}
															numberOfVotes={voteState.numberOfVotes}
															avatars={voteState.avatars}
															numConnectedPlayers={voteState.numConnectedPlayers}
															onResetTimer={onResetTimer}
															voteMode={voteMode}
															onNewVoteMode={onNewVoteMode}
															resetTimer={resetTimerActive}
															reducedMotion={reducedMotion}
														/>
													)}
													{PLAY_STATUES_SLIDE.includes(statusWithProgress.name) && (
														<>
															<Slide
																countryCode={countryCode}
																doublePoints={doublePoints}
																correctAnswerFeature={sendPlace && correctAnswerFeature}
																haveLocalPlayer={haveLocalPlayer}
																hideIncorrectTypeAnswers={hideIncorrectTypeAnswers}
																onComplete={onComplete}
																onFunFactEnd={onFunFactEnd}
																onLoad={onLoad}
																onAnswer={onLocalAnswer}
																onQuestionStart={onQuestionStart}
																onError={onError}
																onLocationContinue={onLocationContinue}
																isPaused={isPaused}
																player={players.get(PLAYING_HOST_CONNECTION_ID)}
																players={players}
																placeType={placeType}
																setPaused={setPaused}
																setPlaylistSkip={setPlaylistSkip}
																setSlideTypeAnswer={onSetSlideTypeAnswer}
																setSlideMediaIsPlayingWithSound={
																	setSlideMediaIsPlayingWithSound
																}
																setFunFactMediaIsPlayingWithSound={
																	setFunFactMediaIsPlayingWithSound
																}
																slide={desktopSlide}
																slideIndex={slideIndex}
																statusWithProgress={statusWithProgress}
																showAnswersLocation={
																	haveLocalPlayer
																		? submittedAnswer
																		: enoughActivePlayersHaveAnswered
																}
																submittedAnswer={submittedAnswer}
																submittedAnswerProgress={submittedAnswerProgress}
																typeAnswerState={cloneDeep(typeAnswerState)}
																isHost={true}
																onLocalJoin={onLocalJoin}
																updateDuration={updateDuration}
																voiceOverride={voiceOverride}
																reducedMotion={reducedMotion}
																teams={!previewMode ? teams : new Map()}
																teamMode={!previewMode ? teamMode : false}
															/>
															<div
																id={STREET_VIEW_PORTAL_ID}
																className="md:fixed absolute top-0 left-0 w-0 h-0 overflow-hidden"
															/>
														</>
													)}
													{statusWithProgress.name === PLAY_STATUS_AI_DISCLAIMER && (
														<AIDisclaimer quiz={quiz} visible={true} />
													)}
													{statusWithProgress.name === PLAY_STATUS_BEFORE_LAST_SLIDE && (
														<Billboard
															text1="Final question"
															text2="Double points!"
															visible={true}
														/>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					);
				}

				if (!isAIGeneratedQuiz && isConnecting) {
					return (
						<div className="absolute inset-0">
							<Connecting />
						</div>
					);
				}

				return (
					<>
						<div
							className={tailwindCascade(
								"md:justify-center flex flex-col items-center justify-start w-full h-full select-text",
								{
									invisible: showLoadingQuestions && !aiMode,
								}
							)}
						>
							<Lobby
								ref={lobbyRef}
								roomId={roomId}
								onStart={onStart}
								onLocalJoin={onLocalJoin}
								quiz={quiz}
								quickMode={quickMode}
								isHost={true}
								showCode={showCode}
								joinOpen={joinOpen}
								mutePlayerNames={mutePlayerNames}
								reducedMotion={reducedMotion}
								players={players}
								aiQuery={aiQuery}
								aiMode={aiMode}
								aiError={aiError}
								aiGenerating={aiGenerating}
								aiGeneratingInfo={aiGeneratingInfo}
								aiProcessTime={aiProcessTime}
								aiLanguage={aiLanguage}
								progress={aiProgress}
								disableYoutubeSetting={disableYoutubeSetting}
								teamMode={teamMode}
								onTeamEdit={onTeamEdit}
								teams={teams}
								setQuiz={setQuiz}
							/>
						</div>
						{showLoadingQuestions && !aiMode && (
							<Modal>
								<div className="bg-petrol-dark rounded-2xl flex flex-col items-center gap-8 p-12">
									<Loading />
								</div>
							</Modal>
						)}
					</>
				);
			})()}

			<Prefetch slide={nextSlide} isHost={true} voiceOverride={voiceOverride} />
		</ScaleWrapper>
	) : null;
}
export function toSlideObject(slide, index) {
	if (!slide) {
		return undefined;
	}
	const slideObject = { index };

	if (isEmpty(slide)) {
		return {};
	}

	console.assert("type" in slide, "slide must have type property!");
	slideObject.type = slide.type;

	for (const prop of ["question", "answerTime", "revealType"]) {
		if (prop in slide) {
			slideObject[prop] = slide[prop];
		}
	}
	if (isArray(slide.answers)) {
		slideObject.answers = slide.answers;
	}
	const media = getMediaBySlide(slide);
	if (media) {
		slideObject.media = media;
	}
	slideObject.isNumeric =
		slideObject.type &&
		slideObject.type === SLIDE_TYPE_TYPE_ANSWER &&
		slideObject.answers &&
		isArray(slideObject.answers) &&
		slideObject.answers.length > 0 &&
		!isNaN(parseFloat(slideObject.answers[0]));

	slideObject.funFact = slide.funFact;
	slideObject.funFactMedia = getFunFactMediaBySlide(slide);
	slideObject.questionVoice = slide.questionVoice;
	slideObject.answerVoice = slide.answerVoice;
	slideObject.id = slide.id;

	return slideObject;
}

export function typeAnswerTest(value, correctValues) {
	if (!isString(value) || !isArray(correctValues) || !correctValues.every(isString)) {
		return undefined;
	}
	value = value.toLowerCase().trim();

	if (DEBUG_TULOU_IS_ALWAYS_RIGHT && value === "tulou") {
		return "correct";
	}
	correctValues = correctValues.map((str) => str.toLocaleLowerCase().trim());

	if (correctValues.includes(value)) {
		return "correct";
	}
	const numericValue = parseFloat(value);

	for (let correctValue of correctValues) {
		const correctNumericValue = parseFloat(correctValue);
		if (
			isInteger(correctNumericValue) &&
			isInteger(numericValue) &&
			Math.abs(correctNumericValue - numericValue) <= 1
		) {
			return "close";
		} else {
			const distance = damerauLevenshtein(value, correctValue);
			if (distance === 1) {
				return "close";
			}
		}
	}
	return "wrong";
}

function getQuizForSending(quiz) {
	return quiz
		? {
				name: quiz.name,
				media: quiz.media,
				owner: quiz.owner,
				slides: quiz.slides.map((slide) => ({
					...pick(slide, ["media", "funFactMedia"]),
					quiz: omit(slide.quiz, ["slides"]),
				})),
		  }
		: null;
}
