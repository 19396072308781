import React from "react";
import { useRouter } from "next/router";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";
import Button from "@/components/interactives/Button";
import Modal from "@/components/Modal";

export default function LeaveDialog({ stayURL, leaveURL, setLeaveURL, stopHosting }) {
	const router = useRouter();

	const onCancel = () => {
		setLeaveURL(null);
		router.push(stayURL, stayURL, { shallow: true });
	};

	return (
		<Modal
			className={tailwindCascade("text-white text-xl", "flex flex-col justify-around items-center space-y-2")}
			onCancel={onCancel}
		>
			<div className="bg-petrol-dark rounded-2xl flex flex-col justify-center max-w-md p-12 space-y-6">
				<p className="w-full text-lg font-black text-center text-white whitespace-pre-wrap">
					{trans(
						"Would you like to continue hosting? The same PIN will be used and all players stay connected until you stop hosting."
					)}
				</p>
				<Button
					color="green-light"
					className="text-white"
					onClick={() => {
						router.push(leaveURL);
					}}
				>
					<span className="md:inline hidden">{trans("Leave — Keep hosting")}</span>
					<span className="md:hidden inline">{trans("Keep hosting")}</span>
				</Button>
				<Button
					color="pink"
					className="text-white"
					onClick={() => {
						stopHosting();
						router.push(leaveURL);
					}}
				>
					<span className="md:inline hidden">{trans("Leave — Stop hosting")}</span>
					<span className="md:hidden inline">{trans("Stop hosting")}</span>
				</Button>
				<Button color="white" onClick={onCancel}>
					{trans("Cancel")}
				</Button>
			</div>
		</Modal>
	);
}
