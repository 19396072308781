import { imageCacheSource } from "@/api/helpers";
import { CDN_BASE_URL } from "@/constants";
import endsWith from "lodash/endsWith";
import isFinite from "lodash/isFinite";
import { getRevealTime } from "./answerTimes";

export default function getMediaBySlide(slide) {
	if (slide && slide.media) {
		const media = {
			slideId: slide.id,
			source: slide?.media?.source, // for seeding random reveal
		};

		if (slide.revealType) {
			media.revealType = slide.revealType;
			media.revealDurationMs = getRevealTime({ answerTime: slide.answerTime, slideType: slide.type }) * 1000;
		}

		if (slide.media.source.startsWith("street/")) {
			media.type = "street";
			const [, lat, lng, heading, pitch, zoom] = slide.media.source.split("/").map(parseFloat);
			media.position = { lat, lng };
			media.pov = { heading, pitch, zoom };
			return media;
		}

		const youtubeId = slide.media.source.indexOf("youtube/") === 0 ? slide.media.source.substring(8) : null;
		if (youtubeId) {
			media.type = "youtube";
			media.id = youtubeId;
			media.autoplay = slide.media.trim && slide.media.trim.autoplay;
			media.volume = slide.media.trim && (isFinite(slide.media.trim.volume) ? slide.media.trim.volume : 1);
			media.start = slide.media.trim && slide.media.trim.start;
			media.duration = slide.media.trim && slide.media.trim.duration;
			media.loop = slide.media.trim && slide.media.trim.loop;

			return media;
		}

		const url =
			endsWith(slide.media.source, ".paint.svg") ||
			(slide.media.source.startsWith("uploads/") && slide.media.source.endsWith(".gif"))
				? `${CDN_BASE_URL}/${slide.media.source}`
				: imageCacheSource(slide.media.source, { height: 600, width: 800, transform: slide.media.transform });

		if (url) {
			media.type = "image";
			media.url = url;
			media.transformX = slide.media.transform.x || 0;
			media.transformY = slide.media.transform.y || 0;
			media.transformZ = slide.media.transform.z || 1;
			media.image = slide.source;
			media.generated = slide.media.generated;

			if (endsWith(media.url, ".svg")) {
				media.type = "svg";
			}

			return media;
		}
	}

	return null;
}

export function getFunFactMediaBySlide(slide) {
	if (slide && slide.funFactMedia) {
		const media = {
			slideId: slide.id,
		};

		const id = slide.funFactMedia.source.indexOf("youtube/") === 0 ? slide.funFactMedia.source.substring(8) : null;
		if (id) {
			media.type = "youtube";
			media.id = id;
			media.autoplay = slide.funFactMedia.trim && slide.funFactMedia.trim.autoplay;
			media.volume =
				slide.funFactMedia.trim &&
				(isFinite(slide.funFactMedia.trim.volume) ? slide.funFactMedia.trim.volume : 1);
			media.start = slide.funFactMedia.trim && slide.funFactMedia.trim.start;
			media.duration = slide.funFactMedia.trim && slide.funFactMedia.trim.duration;
			media.loop = slide.funFactMedia.trim && slide.funFactMedia.trim.loop;

			return media;
		}

		const url =
			endsWith(slide.funFactMedia.source, ".paint.svg") ||
			(slide.funFactMedia.source.startsWith("uploads/") && slide.funFactMedia.source.endsWith(".gif"))
				? `${CDN_BASE_URL}/${slide.funFactMedia.source}`
				: imageCacheSource(slide.funFactMedia.source, {
						height: 600,
						width: 800,
						transform: slide.funFactMedia.transform,
				  });

		if (url) {
			media.type = "image";
			media.url = url;
			media.transformX = slide.funFactMedia.transform.x || 0;
			media.transformY = slide.funFactMedia.transform.y || 0;
			media.transformZ = slide.funFactMedia.transform.z || 1;
			media.image = slide.source;

			if (endsWith(media.url, ".svg")) {
				media.type = "svg";
			}

			return media;
		}
	}

	return null;
}
