import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import isArray from "lodash/isArray";
import isNull from "lodash/isNull";
import isString from "lodash/isString";
import { useImmer } from "use-immer";

import { apiPatchAudioClip } from "@/api/quiz";

import Modal from "@/components/Modal";
import AvatarCustomize from "@/components/pages/join/AvatarCustomize";

import { sfx } from "@/helpers/audio";
import getVerifiedAvatarSafeName from "@/helpers/getVerifiedAvatarSafeName";

import useRefCallback from "@/hooks/useRefCallback";
import useRefMounted from "@/hooks/useRefMounted";

import usePlayStore from "@/stores/play";
import usePlayerStore from "@/stores/player";

import { PLAYER_NAME_TTS_TRANSFORM } from "@/constants";

/*export interface Team {
	name?: string;
	avatar?: any;
	points: number;
	previousPoints: number;
  players: Array<Player>;
}*/

export function AvatarCustomizeModalTeam({
	roomId,
	useSafeNames,
	onCancel,
	onDone,
	team,
	enableRemoveTeam,
	onRemoveTeam,
	avatar,
	isHost,
	showCode,
	joinOpen,
}) {
	const [mounted, mountedRef] = useRefMounted();

	const avatarCustomizeRef = useRef(null);

	/*const updatePlayerName = usePlayerStore((state) => state.updateName);
	const updatePlayerSafeName = usePlayerStore((state) => state.updateSafeName);
	const updatePlayerNameAndSafeName = usePlayerStore((state) => state.updateNameAndSafeName);
	const updatePlayerAvatar = usePlayerStore((state) => state.updateAvatar);

	const playerName = usePlayerStore((state) => state.name);
	const playerSafeName = usePlayerStore((state) => state.safeName);
	const playerAvatar = usePlayerStore((state) => state.avatar);

	const players = usePlayStore((state) => state.players);*

	const updatePlayer = usePlayStore((state) => state.updatePlayer);*/

	const [teamState, setTeamState] = useImmer(team);

	/*useEffect(() => {
		setTeamState(team);
	}, [setTeamState, team]);*/

	const roomIdRef = useRef(roomId);
	useEffect(() => void (roomIdRef.current = roomId), [roomId]);

	const onCancelRef = useRef(onCancel);
	useEffect(() => void (onCancelRef.current = onCancel), [onCancel]);

	const onDoneRef = useRef(onDone);
	useEffect(() => void (onDoneRef.current = onDone), [onDone]);

	const onChangeNameAvatarCustomize = useCallback(
		(name) => {
			if (!isNull(name)) {
				setTeamState((draft) => void (draft.name = name));
			}
		},
		[setTeamState]
	);

	const onChangeSafeNameAvatarCustomize = useCallback((safeName) => {}, []);

	const onChangeAvatarAvatarCustomize = useCallback(
		(avatar) => {
			if (isArray(avatar)) {
				setTeamState((draft) => void (draft.avatar = avatar));
			}
		},
		[setTeamState]
	);

	const onCancelInternal = useCallback(() => {}, []);

	const onDoneInternal = useCallback(() => {
		if (onDoneRef.current) {
			onDoneRef.current(teamState);
		}
	}, [teamState]);

	const onRemoveTeamInternal = useCallback(() => {
		if (onDoneRef.current) {
			onDoneRef.current(teamState);
		}
		if (onRemoveTeam && team) {
			onRemoveTeam(team.connectionId);
		}
	}, [onRemoveTeam, team, teamState]);

	return mounted ? (
		<Modal
			className="sm:p-4 bg-petrol-dark sm:bg-black sm:bg-opacity-80 z-20 p-0 bg-opacity-100"
			onCancel={onCancelInternal}
		>
			<div className="bg-petrol-dark sm:rounded-2xl sm:h-auto sm:max-w-xl w-full h-full m-auto rounded-none">
				<AvatarCustomize
					ref={avatarCustomizeRef}
					onDone={onDoneInternal}
					onChangeName={onChangeNameAvatarCustomize}
					onChangeSafeName={onChangeSafeNameAvatarCustomize}
					onChangeAvatar={onChangeAvatarAvatarCustomize}
					showDone={true}
					//useSafeNames={useSafeNames}
					useSafeNames={false}
					name={teamState.name}
					//safeName={playerSafeName}
					avatar={avatar}
					teamMode={true}
					enableRemoveTeam={enableRemoveTeam}
					onRemoveTeam={onRemoveTeamInternal}
					roomId={roomId}
					isHost={isHost}
					showCode={showCode}
					joinOpen={joinOpen}
				/>
			</div>
		</Modal>
	) : null;
}
