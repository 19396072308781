import React, { useEffect } from "react";

const INTERACTION_TIMEOUT_EVENT_TYPES = ["mousemove", "mousedown", "touchstart", "touchmove"];

class InteractionTimeout {
	constructor(delay = 5) {
		this.delay = delay;
		this.timeout = null;

		this._bindedOnInteraction = () => this._onInteraction();

		INTERACTION_TIMEOUT_EVENT_TYPES.forEach((type) => {
			document.addEventListener(type, this._bindedOnInteraction);
		});

		this._onInteraction(); // Initial
	}

	_clearTimeout() {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = null;
		}
	}

	_onTimeout() {
		this.timeout = null;
		document.documentElement.classList.add("hide-mouse");
	}

	_onInteraction() {
		document.documentElement.classList.remove("hide-mouse");
		this._clearTimeout();
		this.timeout = setTimeout(this._onTimeout, this.delay * 1000);
	}

	dispose() {
		this._clearTimeout();
		INTERACTION_TIMEOUT_EVENT_TYPES.forEach((type) => {
			document.removeEventListener(type, this._bindedOnInteraction);
		});
		document.documentElement.classList.remove("hide-mouse");
	}
}

export default function HideMouse({ timeout = 5 }) {
	useEffect(() => {
		const interactionTimeout = new InteractionTimeout(timeout);
		return () => interactionTimeout.dispose();
	}, [timeout]);
	return null;
}
