import React, { useEffect, useState } from "react";

import Header from "@/components/Header";
import trans from "@/helpers/trans";
import SyncedAnimatedDots from "@/components/SyncedAnimatedDots";

export default function Loading({ children }) {
	return (
		<div className="relative flex flex-col items-center justify-center w-full h-full transition-opacity opacity-0">
			<Header>
				<span>{children ? children : trans("Loading")}</span>
				<SyncedAnimatedDots />
			</Header>
		</div>
	);
}
