/* eslint-disable no-unreachable */
import React, { useCallback, useEffect } from "react";
import { setInterval, clearInterval } from "@/helpers/workerTimers";
import usePlayStore from "@/stores/play";
import useSettingsStore from "@/stores/settings";
import { isSlideValid } from "@/helpers/quiz";

import {
	SLIDE_TYPE_CHECK_BOXES,
	SLIDE_TYPE_INFO_SLIDE,
	SLIDE_TYPE_LOCATION,
	SLIDE_TYPE_PINPOINT,
	SLIDE_TYPE_RANGE,
	SLIDE_TYPE_REORDER,
	SLIDE_TYPE_TYPE_ANSWER,
} from "@/app-constants.mjs";
import clamp from "lodash/clamp";
import pick from "lodash/pick";

import { getAnswerTime } from "@/helpers/answerTimes";
import router from "next/router";

import {
	PLAY_STATUS_GAME_START,
	PLAY_STATUS_SHOW_GET_READY,
	PLAY_STATUS_HIDE_GET_READY,
	PLAY_STATUS_LOAD_SLIDE,
	PLAY_STATUS_SHOW_QUESTION,
	PLAY_STATUS_SHOW_MEDIA,
	PLAY_STATUS_SHOW_ANSWERS,
	PLAY_STATUS_WAIT_FOR_ANSWER,
	PLAY_STATUS_ALL_ANSWERS_RECEIVED,
	PLAY_STATUS_SHOW_CORRECT_ANSWER,
	PLAY_STATUS_SHOW_FUN_FACT,
	PLAY_STATUS_HIDE_SLIDE,
	PLAY_STATUS_PREP_LEADERBOARD,
	PLAY_STATUS_SHOW_LEADERBOARD,
	PLAY_STATUS_EXIT,
	PLAY_STATUS_SHOW_WINNER,
	PLAY_STATUS_SHOW_RATE,
	PLAY_STATUS_VOTE_SHOW,
	PLAY_STATUS_WAIT_FOR_MEDIA,
	PLAY_STATUS_YOUTUBE_END_1,
	PLAY_STATUS_YOUTUBE_END_2,
	PLAY_STATUS_SHOW_AVATAR_COLORS,
	PLAY_STATUS_SHOW_AVATAR_CORRECTNESS,
	PLAY_STATUS_HIDE_FUN_FACT,
	PLAY_STATUS_SHOW_MAP_PIN,
	PLAY_STATUS_ZOOM_MAP_PIN,
	PLAY_STATUS_SHOW_QUIZNAME,
	PLAY_STATUS_FLUSH,
	PLAY_STATUS_END_OF_SLIDE,
	PLAY_STATUS_VOTE_PREP,
	PLAY_STATUS_BROWSE_MAP,
	PLAY_STATUS_VOTE_SHOW_NEXT_QUIZ,
	PLAY_STATUS_LOAD_QUIZ,
	PLAY_STATUS_VOTE_COMING_UP,
	PLAY_STATUS_BEFORE_LAST_SLIDE,
	PLAY_STATUS_VOTE_PRE_COMING_UP,
	PLAY_STATUS_VOTE_SUSPENSE,
	PLAY_STATUS_VOTE_END,
	PLAY_STATUS_SHOW_RESULTS,
	PLAY_STATUS_HIDE_INPUT,
	QUIZ_DEAL_ID,
	QUIZ_STOP_ID,
	QUIZ_VOTEMODE_ID,
	PLAY_STATUS_AI_DISCLAIMER,
	PLAY_STATUS_RATE_DONE,
} from "@/constants";

import {
	BACKGROUND1,
	BACKGROUND2,
	BACKGROUND3,
	BACKGROUND4,
	BACKGROUND5,
	BACKGROUND6,
	BACKGROUND7,
	BACKGROUND8,
} from "@/colors";
import useWebSocketStore from "@/stores/webSocket";
import { getLeaderBoardPlayers, getLeaderBoardTeams } from "./Leaderboard";
import { getFinalLeaderboard, getFinalReport } from "./Winner";
import { getReadingTime } from "../../../helpers/answerTimes";
import { getConnectedPlayers } from "@/helpers/player";
import useStatusWithProgressStore from "@/stores/statusWithProgress";

const BACKGROUND_COLORS = [
	BACKGROUND1,
	BACKGROUND2,
	BACKGROUND3,
	BACKGROUND4,
	BACKGROUND5,
	BACKGROUND6,
	BACKGROUND7,
	BACKGROUND8,
];

const DISCONNECT_ANSWER_TIMEOUT = 5000; // 5 seconds until player is not considered in allPlayerAnswered

const FOREVER = 65535;

const DEFAULT_ANIMATE_IN_DURATION = 1;
const DEFAULT_QUESTION_ANIMATE_IN_DURATION_MIN = 2;
const DEFAULT_QUESTION_ANIMATE_IN_DURATION_MAX = 10;
const DEFAULT_ANSWERS_ANIMATE_IN_DURATION = 0.75;
const DEFAULT_ANIMATE_OUT_DURATION = 0.25;

const POLL_PERIOD_MS = 10;

export function calculateQuestionAnimateInDuration(question) {
	if (question) {
		const words = question.split(" ").length;
		const wordsPerSecond = 150 / 60;
		return clamp(
			words / wordsPerSecond,
			DEFAULT_QUESTION_ANIMATE_IN_DURATION_MIN,
			DEFAULT_QUESTION_ANIMATE_IN_DURATION_MAX
		);
	}
	return 0;
}

function getCurrentSlides() {
	const { quiz } = usePlayStore.getState();
	if (quiz && quiz.slides) {
		return quiz.slides;
	}
	return null;
}

function getCurrentSlide(checkStatus = true) {
	const currentSlides = getCurrentSlides();
	if (currentSlides) {
		const { slideIndex } = usePlayStore.getState();
		if (currentSlides.length > slideIndex && currentSlides[slideIndex]) {
			return currentSlides[slideIndex];
		}
	}
	return null;
}

function getPreviousSlide() {
	const currentSlides = getCurrentSlides();
	if (currentSlides) {
		const slideIndex = usePlayStore.getState().slideIndex - 1;
		if (slideIndex > 0 && currentSlides.length > slideIndex && currentSlides[slideIndex]) {
			return currentSlides[slideIndex];
		}
	}
	return null;
}

function getCurrentSlideType() {
	const currentSlide = getCurrentSlide();
	return currentSlide ? currentSlide.type : null;
}

function getPreviousSlideType() {
	const previousSlide = getPreviousSlide();
	return previousSlide ? previousSlide.type : null;
}

function getCurrentSlideHasFunFactText() {
	const currentSlide = getCurrentSlide();
	return currentSlide && !!currentSlide.funFact;
}

function getCurrentSlideHasFunFactMedia() {
	const currentSlide = getCurrentSlide();
	return currentSlide && !!currentSlide.funFactMedia;
}

function getCurrentSlideIsValid() {
	const currentSlide = getCurrentSlide(false);
	return currentSlide && !!isSlideValid(currentSlide);
}

function getCurrentSlideAnswerTimeSeconds() {
	const currentSlide = getCurrentSlide();
	return currentSlide
		? getAnswerTime({
				answerTime: currentSlide.answerTime,
				slideType: currentSlide.type,
				revealType: currentSlide.revealType,
				mediaSource: currentSlide.media?.source,
		  })
		: 10; // Default 10 seconds
}

function getNumberOfSlides() {
	const currentSlides = getCurrentSlides();
	return currentSlides ? currentSlides.length : 0;
}

function getIndexOfLastQuestion() {
	const slides = getCurrentSlides();

	const lastQuestionSlide = slides.findLast((slide) => slide.type !== SLIDE_TYPE_INFO_SLIDE);
	return slides.indexOf(lastQuestionSlide);
}

function getCurrentSlideQuestion() {
	const currentSlide = getCurrentSlide();
	return currentSlide ? currentSlide.question : null;
}

function getCurrentSlideQuizName() {
	const currentSlide = getCurrentSlide();
	return currentSlide ? currentSlide.quiz?.name : null;
}

function getCurrentSlideMedia() {
	const currentSlide = getCurrentSlide();
	return currentSlide ? currentSlide.media : null;
}

function getCurrentSlideMediaIsYotube() {
	const currentSlide = getCurrentSlide();
	const media = currentSlide ? currentSlide.media : null;
	return media && media.source && media.source.indexOf("youtube/") === 0;
}

function getCurrentSlideFunFactMediaIsYotube() {
	const currentSlide = getCurrentSlide();
	const media = currentSlide ? currentSlide.funFactMedia : null;
	return media && media.source && media.source.indexOf("youtube/") === 0;
}

function getCurrentSlideVideoTrim() {
	const currentSlide = getCurrentSlide();
	return currentSlide ? currentSlide.videoTrim : null;
}

function getCurrentSlideFunFactDuration(minDuration = 0) {
	const currentSlide = getCurrentSlide();
	const text = currentSlide ? currentSlide.funFact : "";
	const textDuration = getReadingTime(text);

	return Math.max(minDuration, textDuration);
}

export default function Playlist(props) {
	const slideIndex = usePlayStore((state) => state.slideIndex);
	const statusWithProgress = useStatusWithProgressStore((state) => state);

	const updateStatusWithProgressStatus = useStatusWithProgressStore((state) => state.updateStatus);
	const updatePlayStatus = usePlayStore((state) => state.updateStatus);

	const updateStatus = useCallback(
		(...args) => {
			updateStatusWithProgressStatus(...args);
			updatePlayStatus(...args);
		},
		[updatePlayStatus, updateStatusWithProgressStatus]
	);

	const sendRoomMessage = useWebSocketStore((state) => state.sendRoomMessage);
	const previewMode = usePlayStore((state) => state.previewMode);

	const sendRankAndScoreToPlayers = useCallback(() => {
		const connectedPlayersArray = getConnectedPlayers([...usePlayStore.getState().players.values()]);

		const teamMode = useSettingsStore.getState().teamMode;
		if (teamMode) {
			const teams = [...usePlayStore.getState().teams.values()];
			teams.sort((a, b) => b.points - a.points);

			for (const [i, team] of teams.entries()) {
				for (const player of connectedPlayersArray) {
					if (player.teamId === team.connectionId) {
						sendRoomMessage(
							{
								rank: i + 1,
								points: team.points,
							},
							player.connectionId
						);
					}
				}
			}
		} else {
			const sortedConnectedPlayersArray = [...connectedPlayersArray];
			sortedConnectedPlayersArray.sort((a, b) => b.points - a.points);

			for (let i = 0; i < sortedConnectedPlayersArray.length; i++) {
				const player = sortedConnectedPlayersArray[i];
				sendRoomMessage(
					{
						rank: i + 1,
						points: player.points,
					},
					player.connectionId
				);
			}
		}
	}, [sendRoomMessage]);

	const shouldDisplayLeaderboard = (slideType, numPlayedQuestions, slideIndex, numSlides, leaderboardSetting) => {
		if (slideType === SLIDE_TYPE_INFO_SLIDE || slideIndex === numSlides - 1) {
			return false;
		} else {
			return leaderboardSetting;
		}
	};

	const holdThenSetNewState = useCallback(
		({ name, duration = 0, callback = () => {} }) => {
			let ended = false;
			const interval = setInterval(() => {
				if (usePlayStore.getState().slideSkipToNext || usePlayStore.getState().slideComplete) {
					usePlayStore.getState().setSlideSkipToNext(false);
					usePlayStore.getState().setSlideComplete(false);
					updateStatus(PLAY_STATUS_END_OF_SLIDE, 0);
				} else {
					const paused = usePlayStore.getState().paused;
					if (!paused) {
						if (
							(!ended && useStatusWithProgressStore.getState().progress >= 1) ||
							usePlayStore.getState().playlistSkip
						) {
							usePlayStore.getState().setPlaylistSkip(false);
							callback();
							updateStatus(name, duration);
							ended = true;
						}
					}
				}
			}, POLL_PERIOD_MS);
			return () => void clearInterval(interval);
		},
		[updateStatus]
	);

	useEffect(() => {
		switch (statusWithProgress.name) {
			case PLAY_STATUS_GAME_START: {
				// updateBackgroundColor(0);
				if (previewMode) {
					return holdThenSetNewState({ name: PLAY_STATUS_FLUSH });
				} else {
					return holdThenSetNewState({ name: PLAY_STATUS_SHOW_GET_READY, duration: 4.0 });
				}
			}

			case PLAY_STATUS_SHOW_GET_READY: {
				return holdThenSetNewState({ name: PLAY_STATUS_HIDE_GET_READY, duration: 0.5 });
			}

			case PLAY_STATUS_HIDE_GET_READY: {
				if (props.quickMode && useSettingsStore.getState().quizSelectMode === "quiz/vote") {
					return holdThenSetNewState({ name: PLAY_STATUS_VOTE_PRE_COMING_UP, duration: 0.5 });
				} else if (!previewMode && slideIndex === 0 && usePlayStore.getState().aiMode) {
					return holdThenSetNewState({
						name: PLAY_STATUS_AI_DISCLAIMER,
						duration: 5,
					});
				} else {
					return holdThenSetNewState({ name: PLAY_STATUS_FLUSH });
				}
			}

			case PLAY_STATUS_FLUSH: {
				if (getCurrentSlideIsValid()) {
					return holdThenSetNewState({ name: PLAY_STATUS_LOAD_SLIDE, duration: 5 });
				} else {
					return holdThenSetNewState({ name: PLAY_STATUS_END_OF_SLIDE });
				}
			}

			case PLAY_STATUS_LOAD_SLIDE: {
				// HostPage will update the duration of this state when/if slide has loaded
				if (props.quickMode && useSettingsStore.getState().quizSelectMode === "questions/random") {
					return holdThenSetNewState({
						name: PLAY_STATUS_SHOW_QUIZNAME,
						duration: calculateQuestionAnimateInDuration(getCurrentSlideQuizName()) + 2,
					});
				} else if (getCurrentSlideType() !== SLIDE_TYPE_INFO_SLIDE) {
					return holdThenSetNewState({
						name: PLAY_STATUS_SHOW_QUESTION,
						duration: calculateQuestionAnimateInDuration(getCurrentSlideQuestion()),
					});
				} else {
					return holdThenSetNewState({
						name: PLAY_STATUS_SHOW_MEDIA,
						duration: DEFAULT_ANIMATE_IN_DURATION,
					});
				}
			}

			case PLAY_STATUS_SHOW_QUIZNAME: {
				// Assume not SLIDE_TYPE_INFO_SLIDE (because they are not included when playing random slides)
				return holdThenSetNewState({
					name: PLAY_STATUS_SHOW_QUESTION,
					duration: calculateQuestionAnimateInDuration(getCurrentSlideQuestion()),
				});
			}

			case PLAY_STATUS_SHOW_QUESTION: {
				if (getCurrentSlideType() !== SLIDE_TYPE_INFO_SLIDE) {
					return holdThenSetNewState({
						name: PLAY_STATUS_SHOW_MEDIA,
						duration: DEFAULT_ANIMATE_IN_DURATION,
					});
				} else {
					const videoDuration = getCurrentSlideVideoTrim()?.duration || 0;
					return holdThenSetNewState({
						name: PLAY_STATUS_WAIT_FOR_ANSWER,
						duration: getCurrentSlideAnswerTimeSeconds() + videoDuration,
					});
				}
			}

			case PLAY_STATUS_SHOW_MEDIA: {
				if (
					![SLIDE_TYPE_INFO_SLIDE, SLIDE_TYPE_LOCATION, SLIDE_TYPE_PINPOINT].includes(getCurrentSlideType())
				) {
					return holdThenSetNewState({
						name: PLAY_STATUS_SHOW_ANSWERS,
						duration: DEFAULT_ANIMATE_IN_DURATION,
					});
				} else {
					return holdThenSetNewState({ name: PLAY_STATUS_WAIT_FOR_MEDIA, duration: 1 });
				}
			}

			case PLAY_STATUS_SHOW_ANSWERS: {
				return holdThenSetNewState({ name: PLAY_STATUS_WAIT_FOR_MEDIA, duration: 1 });
			}

			case PLAY_STATUS_WAIT_FOR_MEDIA: {
				// HostPage will update the duration of this state when/if media is ready
				const videoDuration = getCurrentSlideVideoTrim()?.duration || 0;
				return holdThenSetNewState({
					name: PLAY_STATUS_WAIT_FOR_ANSWER,
					duration: getCurrentSlideAnswerTimeSeconds() + videoDuration,
				});
			}

			case PLAY_STATUS_WAIT_FOR_ANSWER: {
				// HostPage will update the duration of this state when/if all players have answered
				const duration = getCurrentSlideMedia() && getCurrentSlideMediaIsYotube() ? 2 : 1;
				return holdThenSetNewState({ name: PLAY_STATUS_ALL_ANSWERS_RECEIVED, duration });
			}

			case PLAY_STATUS_ALL_ANSWERS_RECEIVED: {
				sendRoomMessage({ correctAnswerReveal: getCurrentSlideType() === SLIDE_TYPE_TYPE_ANSWER });
				switch (getCurrentSlideType()) {
					case SLIDE_TYPE_RANGE:
						return holdThenSetNewState({ name: PLAY_STATUS_HIDE_INPUT, duration: 1 });
					case SLIDE_TYPE_PINPOINT:
						return holdThenSetNewState({
							name: PLAY_STATUS_SHOW_CORRECT_ANSWER,
							DEFAULT_ANIMATE_IN_DURATION,
						});
					case SLIDE_TYPE_INFO_SLIDE:
						return holdThenSetNewState({
							name: PLAY_STATUS_HIDE_SLIDE,
							duration: DEFAULT_ANIMATE_OUT_DURATION,
						});
					default:
						return holdThenSetNewState({ name: PLAY_STATUS_SHOW_AVATAR_COLORS });
				}
			}

			case PLAY_STATUS_HIDE_INPUT: {
				return holdThenSetNewState({ name: PLAY_STATUS_SHOW_RESULTS, duration: 1 });
			}

			case PLAY_STATUS_SHOW_RESULTS: {
				return holdThenSetNewState({ name: PLAY_STATUS_SHOW_AVATAR_COLORS, duration: 1 });
			}

			case PLAY_STATUS_SHOW_AVATAR_COLORS: {
				let showCorrectAnswerDuration = DEFAULT_ANIMATE_IN_DURATION;
				switch (getCurrentSlideType()) {
					case SLIDE_TYPE_RANGE:
						showCorrectAnswerDuration = 3;
						break;
					case SLIDE_TYPE_REORDER:
						showCorrectAnswerDuration = Infinity;
						break;
				}

				return holdThenSetNewState({
					name: PLAY_STATUS_SHOW_CORRECT_ANSWER,
					duration: showCorrectAnswerDuration,
				});
			}

			case PLAY_STATUS_SHOW_CORRECT_ANSWER: {
				const duration =
					getCurrentSlideType() === SLIDE_TYPE_RANGE ||
					!(getCurrentSlideHasFunFactText() && getCurrentSlideHasFunFactMedia())
						? 3.5
						: 0.5;

				return holdThenSetNewState({ name: PLAY_STATUS_SHOW_AVATAR_CORRECTNESS, duration });
			}

			case PLAY_STATUS_SHOW_AVATAR_CORRECTNESS: {
				if (getCurrentSlideType() === SLIDE_TYPE_LOCATION) {
					return holdThenSetNewState({ name: PLAY_STATUS_YOUTUBE_END_1, duration: 2 });
				} else {
					const duration = getCurrentSlideHasFunFactText() || getCurrentSlideHasFunFactMedia() ? 1 : 2;
					return holdThenSetNewState({ name: PLAY_STATUS_YOUTUBE_END_1, duration });
				}
			}

			case PLAY_STATUS_YOUTUBE_END_1: {
				if (getCurrentSlideType() === SLIDE_TYPE_LOCATION) {
					return holdThenSetNewState({
						name: PLAY_STATUS_HIDE_SLIDE,
						duration: DEFAULT_ANIMATE_OUT_DURATION,
					});
				} else if (getCurrentSlideHasFunFactMedia()) {
					if (getCurrentSlideFunFactMediaIsYotube()) {
						return holdThenSetNewState({ name: PLAY_STATUS_SHOW_FUN_FACT, duration: Infinity }); // HostPage-controlled
					} else {
						return holdThenSetNewState({
							name: PLAY_STATUS_SHOW_FUN_FACT,
							duration: getCurrentSlideFunFactDuration(5),
						});
					}
				} else if (getCurrentSlideHasFunFactText()) {
					return holdThenSetNewState({
						name: PLAY_STATUS_SHOW_FUN_FACT,
						duration: getCurrentSlideFunFactDuration(3),
					});
				} else {
					return holdThenSetNewState({
						name: PLAY_STATUS_HIDE_SLIDE,
						duration: DEFAULT_ANIMATE_OUT_DURATION,
					});
				}
			}

			case PLAY_STATUS_SHOW_FUN_FACT: {
				if (getCurrentSlideType() === SLIDE_TYPE_LOCATION) {
					return holdThenSetNewState({ name: PLAY_STATUS_HIDE_FUN_FACT, duration: 1 });
				} else {
					return holdThenSetNewState({ name: PLAY_STATUS_YOUTUBE_END_2, duration: 1 });
				}
			}

			case PLAY_STATUS_YOUTUBE_END_2: {
				return holdThenSetNewState({ name: PLAY_STATUS_HIDE_SLIDE, duration: DEFAULT_ANIMATE_OUT_DURATION });
			}

			case PLAY_STATUS_HIDE_FUN_FACT: {
				if (getCurrentSlideType() === SLIDE_TYPE_LOCATION) {
					return holdThenSetNewState({ name: PLAY_STATUS_ZOOM_MAP_PIN, duration: 5 });
				} else {
					return holdThenSetNewState({
						name: PLAY_STATUS_HIDE_SLIDE,
						duration: DEFAULT_ANIMATE_OUT_DURATION,
					});
				}
			}

			case PLAY_STATUS_HIDE_SLIDE: {
				const slideType = getCurrentSlideType();
				if (slideType === SLIDE_TYPE_INFO_SLIDE) {
					return holdThenSetNewState({ name: PLAY_STATUS_END_OF_SLIDE });
				} else if (slideType === SLIDE_TYPE_LOCATION) {
					return holdThenSetNewState({ name: PLAY_STATUS_SHOW_MAP_PIN, duration: 2 });
				} else {
					return holdThenSetNewState({ name: PLAY_STATUS_PREP_LEADERBOARD });
				}
			}

			case PLAY_STATUS_SHOW_MAP_PIN: {
				if (getCurrentSlideHasFunFactText() || getCurrentSlideHasFunFactMedia()) {
					return holdThenSetNewState({
						name: PLAY_STATUS_SHOW_FUN_FACT,
						duration: getCurrentSlideFunFactDuration(5),
					});
				} else {
					return holdThenSetNewState({ name: PLAY_STATUS_ZOOM_MAP_PIN, duration: 5 });
				}
			}

			case PLAY_STATUS_ZOOM_MAP_PIN: {
				return holdThenSetNewState({ name: PLAY_STATUS_BROWSE_MAP, duration: 20 });
			}

			case PLAY_STATUS_BROWSE_MAP: {
				return holdThenSetNewState({ name: PLAY_STATUS_PREP_LEADERBOARD });
			}

			case PLAY_STATUS_PREP_LEADERBOARD: {
				if (previewMode) {
					return holdThenSetNewState({ name: PLAY_STATUS_END_OF_SLIDE });
				}

				const players = usePlayStore.getState().players;
				if (
					players.size > 0 &&
					shouldDisplayLeaderboard(
						getCurrentSlideType(),
						usePlayStore.getState().numPlayedQuestions,
						slideIndex,
						getNumberOfSlides(),
						useSettingsStore.getState().leaderboardSetting
					)
				) {
					if (useSettingsStore.getState().teamMode) {
						const teamsArray = [...usePlayStore.getState().teams.values()];
						const leaderboard = getLeaderBoardTeams(teamsArray);
						usePlayStore.getState().set((state) => {
							state.leaderboard = leaderboard;
						});
					} else {
						const leaderboard = getLeaderBoardPlayers(players);
						usePlayStore.getState().set((state) => {
							state.leaderboard = leaderboard;
						});
					}

					sendRankAndScoreToPlayers();

					return holdThenSetNewState({ name: PLAY_STATUS_SHOW_LEADERBOARD, duration: 20 });
				} else {
					if (slideIndex < getNumberOfSlides() - 1) {
						// Send scores to players when leaderboard is not shown
						// (except for last slide before winner presentation)
						sendRankAndScoreToPlayers();
					}
					return holdThenSetNewState({ name: PLAY_STATUS_END_OF_SLIDE });
				}
			}

			case PLAY_STATUS_SHOW_LEADERBOARD: {
				return holdThenSetNewState({ name: PLAY_STATUS_END_OF_SLIDE });
			}

			case PLAY_STATUS_END_OF_SLIDE: {
				if (
					props.quickMode &&
					useSettingsStore.getState().quizSelectMode === "quiz/vote" &&
					slideIndex < getNumberOfSlides() - 1 &&
					(slideIndex + 1) % useSettingsStore.getState().numQuestionsPerRound === 0
				) {
					return holdThenSetNewState({
						name: PLAY_STATUS_VOTE_PRE_COMING_UP,
						duration: 0.5,
						callback: () => void usePlayStore.getState().nextSlide(),
					});
				} else if (!previewMode && slideIndex === getIndexOfLastQuestion() - 1) {
					return holdThenSetNewState({
						name: PLAY_STATUS_BEFORE_LAST_SLIDE,
						duration: 5,
					});
				} else if (slideIndex < getNumberOfSlides() - 1) {
					return holdThenSetNewState({
						name: PLAY_STATUS_FLUSH,
						callback: () => void usePlayStore.getState().nextSlide(),
					});
				} else {
					if (
						props.quickMode &&
						getNumberOfSlides() <
							useSettingsStore.getState().numRoundsPerGame *
								useSettingsStore.getState().numQuestionsPerRound
					) {
						return holdThenSetNewState({ name: PLAY_STATUS_VOTE_PREP, duration: Infinity });
					}

					usePlayStore.getState().set((state) => {
						state.leaderboard = getFinalLeaderboard(
							usePlayStore.getState().players,
							usePlayStore.getState().teams,
							useSettingsStore.getState().teamMode
						);
					});

					usePlayStore.getState().set((state) => {
						state.report = getFinalReport(
							usePlayStore.getState().players,
							usePlayStore.getState().quiz?.slides
						);
					});

					if (previewMode) {
						// stay here
						return holdThenSetNewState({ name: PLAY_STATUS_END_OF_SLIDE, Infinity });
					} else {
						return holdThenSetNewState({
							name: PLAY_STATUS_SHOW_WINNER,
							duration: Infinity,
						});
					}
				}
			}

			case PLAY_STATUS_AI_DISCLAIMER: {
				return holdThenSetNewState({
					name: PLAY_STATUS_FLUSH,
				});
			}

			case PLAY_STATUS_BEFORE_LAST_SLIDE: {
				return holdThenSetNewState({
					name: PLAY_STATUS_FLUSH,
					duration: 0.5,
					callback: () => void usePlayStore.getState().nextSlide(),
				});
			}

			case PLAY_STATUS_SHOW_WINNER: {
				return holdThenSetNewState({ name: PLAY_STATUS_SHOW_RATE, duration: Infinity });
			}

			case PLAY_STATUS_SHOW_RATE: {
				return holdThenSetNewState({
					name: PLAY_STATUS_RATE_DONE,
					duration: 0.5,
				});
			}

			case PLAY_STATUS_RATE_DONE: {
				return holdThenSetNewState({ name: PLAY_STATUS_EXIT, duration: Infinity });
			}

			case PLAY_STATUS_VOTE_PRE_COMING_UP: {
				return holdThenSetNewState({
					name: PLAY_STATUS_VOTE_COMING_UP,
					duration: 6,
				});
			}

			case PLAY_STATUS_VOTE_COMING_UP: {
				return holdThenSetNewState({
					name: PLAY_STATUS_VOTE_PREP,
					duration: Infinity,
				});
			}

			case PLAY_STATUS_VOTE_PREP: {
				return holdThenSetNewState({
					name: PLAY_STATUS_VOTE_SHOW,
					duration: 25,
				});
			}

			case PLAY_STATUS_VOTE_SHOW: {
				return holdThenSetNewState({ name: PLAY_STATUS_VOTE_SUSPENSE, duration: 1 });
			}

			case PLAY_STATUS_VOTE_SUSPENSE: {
				if (usePlayStore.getState().nextQuiz === QUIZ_STOP_ID) {
					return updateStatus(PLAY_STATUS_EXIT, Infinity);
				}
				return holdThenSetNewState({ name: PLAY_STATUS_VOTE_SHOW_NEXT_QUIZ, duration: 5 });
			}

			case PLAY_STATUS_VOTE_SHOW_NEXT_QUIZ: {
				if (usePlayStore.getState().nextQuiz === QUIZ_DEAL_ID) {
					return holdThenSetNewState({ name: PLAY_STATUS_VOTE_PREP, duration: Infinity });
				} else {
					return holdThenSetNewState({ name: PLAY_STATUS_VOTE_END, duration: 1 });
				}
			}

			case PLAY_STATUS_VOTE_END: {
				const nextQuizId = usePlayStore.getState().nextQuiz;

				if (props.quickMode && useSettingsStore.getState().quizSelectMode === "quiz/vote" && nextQuizId) {
					return holdThenSetNewState({ name: PLAY_STATUS_LOAD_QUIZ, duration: Infinity });
				} else if (nextQuizId && nextQuizId !== QUIZ_STOP_ID) {
					return holdThenSetNewState({ name: PLAY_STATUS_EXIT, duration: Infinity });
				} else {
					return updateStatus(PLAY_STATUS_EXIT, Infinity);
				}
			}

			case PLAY_STATUS_LOAD_QUIZ: {
				return holdThenSetNewState({ name: PLAY_STATUS_FLUSH });
			}

			case PLAY_STATUS_EXIT: {
				usePlayStore.getState().reset(null, false, true);
				useStatusWithProgressStore.getState().reset(); // this will put us in the LOBBY state
				if (!props.quickMode) {
					router.replace(`/play/${QUIZ_VOTEMODE_ID}/`);
				}
			}
		}
	}, [
		holdThenSetNewState,
		previewMode,
		props.quickMode,
		sendRankAndScoreToPlayers,
		sendRoomMessage,
		slideIndex,
		statusWithProgress.name,
		// updateBackgroundColor,
		updateStatus,
	]);

	return <></>;
}
