import React from "react";

import Header from "@/components/Header";
import trans from "@/helpers/trans";

export default function Connecting() {
	return (
		<div className="flex flex-col items-center justify-center w-full h-full">
			<Header>{trans("Connecting to server...")}</Header>
		</div>
	);
}
