import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { enableMapSet } from "immer";
import isArray from "lodash/isArray";
import isNull from "lodash/isNull";
import isString from "lodash/isString";

import { apiPatchAudioClip } from "@/api/quiz";

import Modal from "@/components/Modal";
import AvatarCustomize from "@/components/pages/join/AvatarCustomize";

import { sfx } from "@/helpers/audio";
import getVerifiedAvatarSafeName from "@/helpers/getVerifiedAvatarSafeName";

import useRefCallback from "@/hooks/useRefCallback";
import useRefMounted from "@/hooks/useRefMounted";

import usePlayStore from "@/stores/play";
import usePlayerStore from "@/stores/player";

import { PLAYER_NAME_TTS_TRANSFORM, PLAYING_HOST_CONNECTION_ID } from "@/constants";

enableMapSet();

export function AvatarCustomizeModal({
	roomId,
	useSafeNames,
	onCancel,
	onDone,
	onRoomMessage,
	onRoomJoin,
	playerState,
	isHost,
	showCode,
	joinOpen,
}) {
	const [mounted, mountedRef] = useRefMounted();

	const avatarCustomizeRef = useRef(null);

	const updatePlayerName = usePlayerStore((state) => state.updateName);
	const updatePlayerSafeName = usePlayerStore((state) => state.updateSafeName);
	const updatePlayerNameAndSafeName = usePlayerStore((state) => state.updateNameAndSafeName);
	const updatePlayerAvatar = usePlayerStore((state) => state.updateAvatar);

	const playerName = usePlayerStore((state) => state.name);
	const playerSafeName = usePlayerStore((state) => state.safeName);
	const playerAvatar = usePlayerStore((state) => state.avatar);

	const players = usePlayStore((state) => state.players);

	const updatePlayer = usePlayStore((state) => state.updatePlayer);

	const roomIdRef = useRef(roomId);
	useEffect(() => void (roomIdRef.current = roomId), [roomId]);

	const onCancelRef = useRef(onCancel);
	useEffect(() => void (onCancelRef.current = onCancel), [onCancel]);

	const onDoneRef = useRef(onDone);
	useEffect(() => void (onDoneRef.current = onDone), [onDone]);

	const onRoomMessageRef = useRef(onRoomMessage);
	useEffect(() => void (onRoomMessageRef.current = onRoomMessage), [onRoomMessage]);

	const onRoomJoinRef = useRef(onRoomJoin);
	useEffect(() => void (onRoomJoinRef.current = onRoomJoin), [onRoomJoin]);

	const onChangeNameAvatarCustomize = useCallback(
		(name) => {
			if (!isNull(name)) {
				if (getVerifiedAvatarSafeName(name)) {
					updatePlayerNameAndSafeName(name, name);
				} else {
					updatePlayerName(name);
				}
			}
		},
		[updatePlayerName, updatePlayerNameAndSafeName]
	);

	const onChangeSafeNameAvatarCustomize = useCallback(
		(safeName) => {
			if (!isNull(safeName)) {
				if (getVerifiedAvatarSafeName(usePlayerStore.getState().name)) {
					updatePlayerNameAndSafeName(safeName, safeName);
				} else {
					updatePlayerSafeName(safeName);
				}
			}
		},
		[updatePlayerNameAndSafeName, updatePlayerSafeName]
	);

	const onChangeAvatarAvatarCustomize = useCallback(
		(avatar) => {
			if (isArray(avatar)) {
				updatePlayerAvatar(avatar);
			}
		},
		[updatePlayerAvatar]
	);

	const [, onDoneInternalRef] = useRefCallback(() => {
		if (onRoomJoinRef.current) {
			onRoomJoinRef.current(
				{
					roomId,
					connectionId: PLAYING_HOST_CONNECTION_ID,
					valid: false,
				},
				(message, connectionId) => {
					if (onRoomMessageRef.current) {
						const player = {
							name: playerState.name,
							country: playerState.country,
						};

						player.avatar = isArray(playerState.avatar)
							? playerState.avatar
							: isString(playerState.avatar)
							? playerState.avatar
							: null;

						onRoomMessageRef.current(
							{
								connectionId,
								player,
							},
							(message, connectionId) => {
								if (onDoneRef.current) {
									onDoneRef.current();
									if (mountedRef.current) {
										updatePlayer(PLAYING_HOST_CONNECTION_ID, (player) => {
											player.created = true;
										});
									}

									const voiceClipName = player?.name;
									apiPatchAudioClip("Samantha", PLAYER_NAME_TTS_TRANSFORM(voiceClipName))
										.then((result) => {
											if (mountedRef.current) {
												updatePlayer(PLAYING_HOST_CONNECTION_ID, (player) => {
													player.voiceClip = result?.filename;
													player.voiceClipName = voiceClipName;
												});
											}
										})
										.catch(console.error);
								}
							}
						);
					}
				}
			);
		}

		sfx.play("playerReady");
	}, [roomId, playerState]);

	const onCancelInternal = useCallback(() => {
		if (players.has(PLAYING_HOST_CONNECTION_ID) && avatarCustomizeRef.current) {
			avatarCustomizeRef.current.done();
		} else {
			if (onCancelRef.current) {
				onCancelRef.current();
			}
		}
	}, [players]);

	const onDoneInternal = useCallback(() => {
		if (onDoneInternalRef.current) {
			onDoneInternalRef.current();
		}
	}, [onDoneInternalRef]);

	return mounted ? (
		<Modal
			className="sm:p-4 bg-petrol-dark sm:bg-black sm:bg-opacity-80 z-20 p-0 bg-opacity-100"
			onCancel={onCancelInternal}
		>
			<div className="bg-petrol-dark sm:rounded-2xl sm:h-auto sm:max-w-xl w-full h-full m-auto rounded-none">
				<AvatarCustomize
					ref={avatarCustomizeRef}
					onDone={onDoneInternal}
					onChangeName={onChangeNameAvatarCustomize}
					onChangeSafeName={onChangeSafeNameAvatarCustomize}
					onChangeAvatar={onChangeAvatarAvatarCustomize}
					showDone={true}
					useSafeNames={useSafeNames}
					name={playerName}
					safeName={playerSafeName}
					avatar={playerAvatar}
					isHost={isHost}
					roomId={roomId}
					showCode={showCode}
					joinOpen={joinOpen}
				/>
			</div>
		</Modal>
	) : null;
}
